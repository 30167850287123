<template>
  <div>
    <div class="pb-4" v-if="BannerImage">
      <v-img :src="BannerImage" max-width="80%"></v-img>
    </div>
    <div class="py-3" v-if="showReturnButton">
      <v-btn
        depressed
        color="#de6139"
        :to="pkg.CustomerListingUrl"
        class="white--text"
        >Shop more {{ pkg.CustomerCategoryName }} events</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["pkg", "showReturnButton"],
  computed: {
    BannerImage() {
      return this.pkg.BannerImage
        ? `https://www.tempotickets.com/tempotickets/file_store/${this.pkg.BannerImage}`
        : null;
    }
  }
};
</script>
