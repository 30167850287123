<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <div style="text-align: center; width: 90%">
        <span
          style="color: #f00"
          v-if="inventoryResponse"
          v-text="inventoryResponse.message"
        ></span
        ><br />
        <span v-if="isInventoryLoading">Loading your tickets ...</span>
      </div>
      <v-progress-linear
        v-if="isInventoryLoading"
        indeterminate
        color="primary"
        class="mx-6"
      ></v-progress-linear>
      <div style="text-align: center; width: 90%">
        <v-alert v-if="showLoadingError">
          A network error has stalled your transaction. Please
          <router-link :to="customer.CustomerListingUrl"
            >click here</router-link
          >
          to return and resubmit.
        </v-alert>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      showLoadingError: false
    };
  },
  computed: {
    ...mapGetters(["isEvent", "isInventoryLoading"]),
    ...mapState({
      customer: state => state.customer,
      inventoryResponse: state => state.inventoryResponse,
      nextScreen: state =>
        state.event.TicketingMode ? "/payment" : "/eventQuestions"
    })
  },
  created() {
    this.showLoadingError = false;
    // TODO: redirect if no active order
    if (this.isEvent) {
      this.$store.dispatch("REQUEST_INVENTORY");
    }
    this.$watch("isEvent", function(newValue) {
      if (newValue) {
        this.$store.dispatch("REQUEST_INVENTORY");
      }
    });
    // this will become true once the requested inventory is held
    this.$watch("isInventoryLoading", function(newValue) {
      // this will change from false to true to false during the life cycle of this page
      if (newValue === false) {
        // check for error
        if (this.inventoryResponse?.error) {
          this.showLoadingError = true;
        } else {
          this.$router.push(this.nextScreen);
        }
      }
    });
  }
};
</script>
