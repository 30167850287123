<template>
  <div v-if="event">
    <div class="pb-4" v-if="BannerImage">
      <v-img :src="BannerImage" max-width="80%"></v-img>
    </div>
    <!--    <div class="py-3" v-if="showReturnButton">-->
    <!--      <v-btn-->
    <!--        depressed-->
    <!--        color="#de6139"-->
    <!--        :to="event.CustomerListingUrl"-->
    <!--        class="white&#45;&#45;text"-->
    <!--        >Shop more {{ event.CustomerCategoryName }} events</v-btn-->
    <!--      >-->
    <!--    </div>-->
    <h2 v-if="showEventName">{{ event.Title }}</h2>
    <div class="text-caption" v-if="event.Producer && showEventName">
      Presented by {{ event.Producer }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["event", "showReturnButton", "showEventName"],
  computed: {
    BannerImage() {
      return this.event.BannerImage
        ? `https://www.tempotickets.com/tempotickets/file_store/${this.event.BannerImage}`
        : null;
    }
  }
};
</script>
