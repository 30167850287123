import Client from "./Client";

export async function findByEvents(EventIDs) {
  const httpClient = new Client();
  const response = await httpClient.post("/questions", {
    body: {
      EventIDs
    }
  });
  return response?.data;
}
