<template>
  <v-sheet class="pa-4" :color="pkg.LandingPageColor" width="80%">
    <v-row v-if="pkg.BadgeText">
      <v-chip class="ma-2" color="#BEE3FF" label>
        <v-icon left color="#0B5289">
          mdi-tag-outline
        </v-icon>
        <span class="tempo-badge">{{ pkg.BadgeText }}</span>
      </v-chip>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <h2 :class="{ 'white--text': pkg.LandingPageTextColor == 'light' }">
          {{ pkg.Title }}
        </h2>
      </v-col>
      <v-col cols="12" md="3">
        <div
          :class="{
            'white--text': pkg.LandingPageTextColor == 'light'
          }"
        >
          {{ pkg.Price | toCurrency }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12" md="4" v-if="pkg.Producer">
            <div
              :class="{
                'tempo-subtitle': pkg.LandingPageTextColor != 'light',
                'tempo-subtitle-light': pkg.LandingPageTextColor == 'light'
              }"
            >
              Presented By
            </div>
            <div
              :class="{
                'white--text': pkg.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              {{ pkg.Producer }}
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div
              :class="{
                'tempo-subtitle': pkg.LandingPageTextColor != 'light',
                'tempo-subtitle-light': pkg.LandingPageTextColor == 'light'
              }"
            >
              Location
            </div>
            <div
              :class="{
                'white--text': pkg.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              <div v-for="(venue, index) in pkg.VenueNames" :key="index">
                {{
                  index === 0 || venue !== pkg.VenueNames[index - 1]
                    ? venue
                    : null
                }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div
              :class="{
                'tempo-subtitle': pkg.LandingPageTextColor != 'light',
                'tempo-subtitle-light': pkg.LandingPageTextColor == 'light'
              }"
            >
              Date(s)
            </div>
            <div
              :class="{
                'white--text': pkg.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              <div v-for="d in pkg.Dates" :key="d.DateID">
                {{ d.Date | toDateWithTime }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn depressed color="primary" :to="pkg.BookingUrl">Select</v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  props: ["pkg"]
};
</script>
<style scoped>
.tempo-subtitle {
  color: #919699;
  font-size: 15px;
}
.tempo-subtitle-light {
  color: #d3e3eb;
  font-size: 15px;
}
.tempo-badge {
  color: #0b5289;
}
</style>
