<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="!agreed"
          color="primary"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          Read and Agree to Terms
        </v-btn>
        <v-btn
          v-else
          color="success"
          outlined
          v-bind="attrs"
          v-on="on"
        >
        <v-icon left>
            mdi-check
        </v-icon>
          Read Terms
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Terms</span>
        </v-card-title>
        <v-card-text>
          {{terms}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="agree"
          >
            I agree to these terms
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
    props: ["terms", "agreed"],
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        agree() {
            this.$emit('on-agree')
            this.dialog = false;
        }
    }
}
</script>