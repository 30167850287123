import {
  findCustomizationsByEvent,
  findEvent
} from "@/repositories/EventRepository";
import {
  holdInventory,
  holdPackageInventory,
  removePackageHold,
  removeTicketHold
} from "@/repositories/InventoryRepository";
import { findByEvents } from "@/repositories/QuestionRepository";
import { formatSingleEvent } from "@/services/EventFormatter";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({});

const plugins =
  process.env.NODE_ENV !== "production"
    ? [Vuex.createLogger(), vuexLocal.plugin]
    : [vuexLocal.plugin];

const store = new Vuex.Store({
  state: {
    event: null,
    package: null,
    customer: null, // i.e. Category
    completedOrder: null,
    user: null,
    cartExpires: 0,
    chosenEventDate: null,
    inventoryResponse: null,
    inventoryLoading: false,
    questions: [],
    donationAmount: 0,
    donationAmountOther: 0,
    appliedCoupon: null,
    ticketQuantities: [],
    terminalReaderSelected: null,
    terminalEnabled: false
  },
  plugins,
  mutations: {
    STORE_USER(state, payload) {
      state.user = payload;
    },
    STORE_CART_EXPIRY(state, payload) {
      state.cartExpires = payload;
    },
    REMOVE_USER(state) {
      state.user = null;
    },
    CLEAR_CART(state) {
      state.ticketQuantities = [];
      state.inventoryResponse = null;
      state.cartExpires = 0;
    },
    STORE_EVENT(state, payload) {
      document.title = payload.Title;
      if (state.EventID !== payload.eventID) {
        state.ticketQuantities = [];
        state.inventoryResponse = null;
        state.cartExpires = 0;
        state.appliedCoupon = null;
      }
      state.event = payload;
    },
    STORE_APPLIED_COUPON(state, payload) {
      state.appliedCoupon = payload;
    },
    REMOVE_APPLIED_COUPON(state) {
      state.appliedCoupon = null;
    },
    REMOVE_EVENT(state) {
      document.title = process.env.VUE_APP_TITLE;
      state.event = null;
      // if an event is removed, let's assume any orders for it should also be removed.
      state.ticketQuantities = [];
      state.inventoryResponse = null;
      state.cartExpires = 0;
      state.appliedCoupon = null;
    },
    COMPLETED_ORDER(state) {
      state.completedOrder = JSON.parse(
        JSON.stringify(state.inventoryResponse)
      );
    },
    STORE_PACKAGE(state, payload) {
      state.package = payload;
    },
    STORE_INVENTORY_RESPONSE(state, payload) {
      state.inventoryResponse = payload;
    },
    UPDATE_INVENTORY_RESPONSE(state, payload) {
      for (const key in payload) {
        state.inventoryResponse[key] = payload[key];
      }
    },
    CLEAR_INVENTORY_RESPONSE(state) {
      state.inventoryResponse = null;
    },
    STORE_TICKET_QUANTITIES(state, payload) {
      state.ticketQuantities = payload;
    },
    UPDATE_TICKET_QUANTITIES(state, payload) {
      state.ticketQuantities?.splice(payload.index, 1, payload.quantity);
    },
    CHOOSE_EVENT_DATE(state, payload) {
      state.chosenEventDate = payload;
    },
    STORE_INVENTORY_LOADING(state, payload) {
      state.inventoryLoading = payload;
    },
    STORE_CUSTOMER(state, payload) {
      state.customer = payload;
    },
    STORE_QUESTIONS(state, payload) {
      state.questions = payload;
    },
    STORE_DONATION_AMOUNT(state, payload) {
      state.donationAmount = payload;
    },
    STORE_DONATION_AMOUNT_OTHER(state, payload) {
      state.donationAmountOther = payload;
    }
  },
  actions: {
    CLEAR_CART({ commit }) {
      commit("CLEAR_CART");
    },
    LOAD_EVENT({ commit }, eventId) {
      findEvent(eventId).then(e => {
        findCustomizationsByEvent(e.EventID).then(c => {
          commit("STORE_PACKAGE", null);
          commit("STORE_EVENT", formatSingleEvent(e, c));
        });
        commit("STORE_CUSTOMER", {
          BannerImage: e.BannerImage,
          CustomerCategoryName: e.CustomerCategoryName,
          CustomerListingUrl: e.CustomerListingUrl
        });
      });
    },
    REQUEST_INVENTORY({ commit, state }) {
      commit("STORE_INVENTORY_LOADING", true);
      const ticket_order = state.event.TicketTypes.map((tt, index) => {
        return {
          id: tt.TypeID,
          quantity: state.ticketQuantities[index]
        };
      }).filter(tt => tt.quantity > 0);
      holdInventory(
        state.event.EventID,
        state.chosenEventDate
          ? state.chosenEventDate.DateID
          : state.event.Dates[0].DateID,
        ticket_order,
        state.inventoryResponse?.inventoryId
      )
        .then(response => {
          if (!response || response.error) {
            commit("STORE_INVENTORY_RESPONSE", {
              error: true
            });
          } else if (
            !response?.packages?.length &&
            !response?.tickets?.length
          ) {
            commit("STORE_INVENTORY_RESPONSE", {});
          } else {
            commit("STORE_INVENTORY_RESPONSE", response);
          }
        })
        .catch(() => {
          commit("STORE_INVENTORY_RESPONSE", {
            error: true
          });
        })
        .finally(() => {
          commit("STORE_INVENTORY_LOADING", false);
        });
    },
    REQUEST_PACKAGE_INVENTORY({ commit, state }) {
      commit("STORE_INVENTORY_LOADING", true);
      holdPackageInventory(
        state.package.PackageID,
        state.ticketQuantities[0],
        state.inventoryResponse?.inventoryId
      )
        .then(response => {
          if (!response || response.error) {
            commit("STORE_INVENTORY_RESPONSE", {
              error: true
            });
          } else if (
            !response?.packages?.length &&
            !response?.tickets?.length
          ) {
            commit("STORE_INVENTORY_RESPONSE", {});
          } else {
            commit("STORE_INVENTORY_RESPONSE", response);
          }
        })
        .catch(() => {
          commit("STORE_INVENTORY_RESPONSE", {
            error: true
          });
        })
        .finally(() => {
          commit("STORE_INVENTORY_LOADING", false);
        });
    },
    LOAD_TICKET_QUANTITIES({ commit, state }, params) {
      if (params.isPackage) {
        const pkg =
          state.inventoryResponse?.packages?.find(
            p => p.id == state.package.PackageID
          ) || null;
        commit("STORE_TICKET_QUANTITIES", [pkg?.quantity || 0]);
      } else if (params.isEvent) {
        const tickets =
          state.inventoryResponse?.tickets?.filter(
            t => t.eventId == state.event.EventID
          ) || [];
        commit(
          "STORE_TICKET_QUANTITIES",
          state.event.TicketTypes.map(
            ticketType =>
              tickets?.find(t => t.id == ticketType.TypeID)?.quantity || 0
          )
        );
      }
    },
    LOAD_ALL_QUESTIONS({ commit, state }) {
      // given all the tickets requested, load each event.
      const pkgTickets = state.inventoryResponse?.packages
        ? state.inventoryResponse.packages
            .map(o => {
              return o.tickets;
            })
            .flat()
            .map(t => t.eventId)
        : [];

      const eventTickets = state.inventoryResponse?.tickets
        ? state.inventoryResponse.tickets.map(t => t.eventId)
        : [];

      findByEvents([...pkgTickets, ...eventTickets]).then(q => {
        commit("STORE_QUESTIONS", q);
      });
    },
    REMOVE_PACKAGE_ORDER({ commit, state }, PackageID) {
      removePackageHold(state.inventoryResponse?.inventoryId, PackageID)
        .then(response => {
          if (!response || response.error) {
            commit("STORE_INVENTORY_RESPONSE", {
              error: true
            });
          } else if (
            !response?.packages?.length &&
            !response?.tickets?.length
          ) {
            commit("STORE_INVENTORY_RESPONSE", {});
          } else {
            commit("STORE_INVENTORY_RESPONSE", response);
          }
        })
        .catch(() => {
          commit("STORE_INVENTORY_RESPONSE", {
            error: true
          });
        })
        .finally(() => {
          commit("STORE_INVENTORY_LOADING", false);
        });
    },
    REMOVE_EVENT_TICKET({ commit, state }, params) {
      removeTicketHold(state.inventoryResponse?.inventoryId, params.TicketID)
        .then(response => {
          if (!response || response.error) {
            commit("STORE_INVENTORY_RESPONSE", {
              error: true
            });
          } else if (
            !response?.packages?.length &&
            !response?.tickets?.length
          ) {
            commit("STORE_INVENTORY_RESPONSE", {});
          } else {
            commit("STORE_INVENTORY_RESPONSE", response);
          }
        })
        .catch(() => {
          commit("STORE_INVENTORY_RESPONSE", {
            error: true
          });
        })
        .finally(() => {
          commit("STORE_INVENTORY_LOADING", false);
        });
    }
  },
  getters: {
    isEvent: state => !!state.event,
    isPackage: state => !!state.package,
    isInventoryLoading: state => state.inventoryLoading,
    getCartLines: (state, getters) => {
      // remove package tickets from the tickets since we want to display
      // packages separately
      const packages = state.inventoryResponse?.packages || [];
      const packageTickets =
        state.inventoryResponse?.packages?.map(p => p.tickets).flat() || [];
      const tickets =
        state.inventoryResponse?.tickets?.filter(
          t => !packageTickets.find(pt => pt.id === t.id)
        ) || [];

      let items = packages.map(p => ({
        ticketId: null,
        packageId: p.id,
        path: p.packagePath,
        title: p.title,
        description: "Combo Package",
        quantity: p.quantity,
        price: p.tickets.reduce(
          (acc, ticket) => acc + parseInt(ticket.price),
          0
        ),
        serviceFee: p.tickets.reduce(
          (acc, ticket) => acc + parseInt(ticket.serviceFee),
          0
        ),
        totalPrice: p.tickets.reduce(
          (acc, ticket) => acc + parseInt(ticket.totalPrice),
          0
        ),
        tickets: p.tickets.map(t => ({
          ticketId: t.id,
          quantity: t.quantity,
          questions: getters.getPerTicketQuestions(t.id)
        }))
      }));

      items = [
        ...items,
        ...tickets.map(t => {
          //apply discount code
          // const dateCheck =
          //   isNaN(new Date(state.appliedCoupon?.LastDay).getTime()) ||
          //   new Date(state.appliedCoupon?.LastDay).getTime() <
          //     new Date().getTime();
          const typeCheck =
            state.appliedCoupon?.TicketTypes?.length > 0
              ? state.appliedCoupon?.TicketTypes?.includes(t.id)
              : true;

          const applyDiscountCode =
            state.appliedCoupon &&
            t.eventId === state.appliedCoupon?.EventID &&
            // dateCheck &&
            typeCheck;

          const totalPrice = getters.getDiscountAppliedTotal(t);

          return {
            eventId: t.eventId,
            ticketId: t.id,
            packageId: null,
            path: `/event/${t.event}`,
            title: t.eventName,
            description: t.description,
            quantity: t.quantity,
            price: t.price,
            totalPrice: totalPrice > 0 ? totalPrice : 0,
            serviceFee: t.serviceFee,
            appliedCouponCode: applyDiscountCode
              ? state.appliedCoupon?.CouponCode
              : "",
            appliedCoupon: applyDiscountCode ? state.appliedCoupon : "",
            questions: getters.getPerTicketQuestions(t.id)
          };
        })
      ];

      return items;
    },
    getOrderTotal: (state, getters) => {
      return state.inventoryResponse?.tickets
        ? state.inventoryResponse.tickets.reduce((tot, tick) => {
            const totalPrice = getters.getDiscountAppliedTotal(tick);
            return tot + totalPrice;
          }, 0)
        : 0;
    },
    getCompletedOrderTotal: state => {
      // TODO this appears to never be used
      return state.completedOrder?.tickets
        ? state.completedOrder.tickets.reduce((tot, tick) => {
            return tot + tick.totalPrice;
          }, 0)
        : 0;
    },
    getOrderServiceFee: state => {
      // TODO this appears to never be used
      return state.inventoryResponse?.tickets
        ? state.inventoryResponse.tickets.reduce((tot, tick) => {
            return tot + tick.totalServiceFee;
          }, 0)
        : null;
    },
    getCompletedTickets: state =>
      // TODO this appears to never be used
      state.completedOrder?.tickets
        ? state.completedOrder.tickets.map(t => {
            return {
              ...t,
              eventPath: `/event/${t.event}`
            };
          })
        : null,
    getPerTicketQuestions: state => ticketTypeId => {
      return state.questions?.filter(t =>
        t.ticketTypes?.includes(ticketTypeId)
      );
    },
    getDiscountAppliedTotal: state => ticket => {
      const dateCheck =
        isNaN(new Date(state.appliedCoupon?.LastDay).getTime()) ||
        new Date(state.appliedCoupon?.LastDay).getTime() > 0 ||
        new Date(state.appliedCoupon?.LastDay).getTime() < new Date().getTime();
      const typeCheck =
        state.appliedCoupon?.TicketTypes?.length > 0
          ? state.appliedCoupon?.TicketTypes?.includes(ticket.id)
          : true;

      let totalPrice = ticket.totalPrice;
      if (
        state.appliedCoupon &&
        ticket.eventId === state.appliedCoupon?.EventID &&
        dateCheck &&
        typeCheck
      ) {
        if (state.appliedCoupon?.Amount > 0) {
          totalPrice =
            parseInt(totalPrice) -
            parseInt(state.appliedCoupon?.Amount) * parseInt(ticket.quantity);
        } else if (state.appliedCoupon?.Percentage > 0) {
          const discount = parseInt(
            parseInt(ticket.price) *
              parseInt(ticket.quantity) *
              parseFloat(state.appliedCoupon?.Percentage)
          );
          totalPrice = parseInt(totalPrice) - parseInt(discount);
        }

        //check the total price to see if it is less than the service fee and if so then juse zero it out becasue it is more than the ticket itself
        if (
          totalPrice <=
          parseInt(ticket.serviceFee) * parseInt(ticket.quantity)
        ) {
          totalPrice = 0;
        }
      }
      return totalPrice;
    },
    getTotalQuantitySelected: state =>
      state.ticketQuantities.reduce((t, q) => t + q, 0),
    getQuestions: state => state.questions?.filter(t => !t.perTicket), // opposite of per ticket questions
    getActualDonationAmount: state =>
      state.donationAmount === "Other"
        ? state.donationAmountOther * 100
        : state.donationAmount
  }
});

export default store;
