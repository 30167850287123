<template>
  <div>
    <div v-if="labelLength > 40">{{ label }}</div>
    <v-text-field
      outlined
      color="black"
      :label="labelLength <= 40 ? label : null"
      :value="value"
      @input="updateValue"
      dense
    ></v-text-field>
  </div>
</template>
<script>
export default {
  name: "text-input",
  props: ["label", "value"],
  emits: ["input"],
  computed: {
    labelLength() {
      return this.label?.length;
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
