import axios from "axios";
const baseDomain = process.env.VUE_APP_API_URL;
const baseUrl = "";

export default class Client {
  constructor() {
    this.client = axios.create({
      baseURL: `${baseDomain}/${baseUrl}`
    });
    this.token = null;
  }

  async post(uri, context) {
    if (!this.token) {
      await this.getToken();
    }
    try {
      return this.client.post(uri, context.body, {
        headers: {
          ...context.headers,
          "X-AUTH-TOKEN": this.token
        }
      });
    } catch (err) {
      console.log("POST ERR", err);
    }
  }

  async get(uri) {
    if (!this.token) {
      await this.getToken();
    }
    try {
      return this.client.get(uri, {
        headers: {
          "X-AUTH-TOKEN": this.token
        }
      });
    } catch (err) {
      console.log("GET ERR", err);
    }
  }

  async delete(uri, context) {
    // TODO: Delete doesn't seem to need a token
    try {
      return this.client.delete(uri, context);
    } catch (err) {
      console.log(err);
    }
  }

  async getToken() {
    const response = await this.client.post("/user/token", {
      op: "generate"
    });
    if (response.data.status === "success") {
      this.token = response.data.token;
    }
  }
}
