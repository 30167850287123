<template>
  <div class="pr-5">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge dot bottom overlap :value="lines && lines.length">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cart-outline</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Your Cart</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="menu = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list subheader two-line>
          <v-list-item v-if="!lines.length">
            <v-list-item-content>
              <v-list-item-title>Cart is Empty</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="line in lines"
            :key="line.packageId || line.ticketId"
          >
            <v-list-item-action>
              <v-btn depressed outlined :to="line.path" @click="menu = false"
                >Edit</v-btn
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                v-text="
                  line.title.length > 30
                    ? line.title.substr(0, 30) + '...'
                    : line.title
                "
              ></v-list-item-title>
              <v-row>
                <v-col md="6"
                  >{{ line.quantity }} x {{ line.description }}</v-col
                >
                <v-col md="6" class="primary--text text-right">{{
                  line.totalPrice | toCurrency
                }}</v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="lines.length"
            depressed
            color="primary"
            @click="goToCheckout"
          >
            Checkout Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapGetters({
      lines: "getCartLines"
    })
  },
  methods: {
    goToCheckout() {
      this.menu = false;
      this.$router.push("/payment");
    }
  }
};
</script>
