<template>
  <v-container class="white pa-4">
    <v-row class="my-2 mx-8">
      <v-col cols="12" md="12">
        <timeout-message
          :expirySeconds="expirySeconds"
          @on-expired="setExpired"
        ></timeout-message>
        <event-header :event="event"></event-header>
        <checkout-steps step="1"></checkout-steps>
        <v-divider class="my-2"></v-divider>
      </v-col>
    </v-row>
    <v-row class="mt-4" justify="center">
      <error-message :message="errorMessage"></error-message>
    </v-row>
    <!-- // ** disabling createAccount  -->
    <!-- <v-row class="my-2 mx-8">
      <v-col cols="12" md="12">
        <div class="text-body-1">
          Would you like to create an account or checkout as a guest?
        </div>
        <v-text-field
          label="Password (required)"
          outlined
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>
    </v-row> -->
    <v-row align="center" justify="space-around">
      <v-btn color="secondary" depressed v-on:click="goToPayment"
        >Check Out As Guest</v-btn
      >
      <!-- <v-btn color="primary" depressed v-on:click="createAccount"
        >Create Account</v-btn
      > -->
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from "vuex";
import CheckoutSteps from "./CheckoutSteps";
import EventHeader from "./EventHeader";
import ErrorMessage from "@/components/shared/ErrorMessage";
import TimeoutMessage from "./TimeoutMessage";
export default {
  components: {
    CheckoutSteps,
    ErrorMessage,
    EventHeader,
    TimeoutMessage
  },
  data() {
    return {
      password: "",
      showPassword: false,
      errorMessage: ""
    };
  },
  computed: mapState({
    order: state => state.order,
    event: state => state.event,
    expirySeconds: state =>
      new Date(state.inventoryResponse?.expires?.date + "Z") / 1000
  }),
  methods: {
    goToPayment() {
      this.$router.push("payment");
    },
    createAccount() {
      this.errorMessage = "";
      Auth.signUp({
        username: this.order.Email,
        password: this.password,
        attributes: {
          given_name: this.order.TicketHolderFirstName,
          family_name: this.order.TicketHolderLastName
        }
      })
        .then(response => {
          this.$store.commit("STORE_USER", {
            sub: response.username, // Cognito username is the sub (unique ID)
            username: this.order.Email,
            firstName: this.order.TicketHolderFirstName,
            lastName: this.order.TicketHolderLastName
          });
          this.$router.push("payment");
        })
        .catch(error => {
          this.errorMessage = error?.message;
        });
    },
    setExpired() {
      this.$store.commit("REMOVE_EVENT");
      this.$router.push("expired");
    }
  }
};
</script>
