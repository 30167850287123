var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4",attrs:{"color":_vm.event.LandingPageColor,"width":"80%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('h2',{class:{ 'white--text': _vm.event.LandingPageTextColor == 'light' }},[_vm._v(" "+_vm._s(_vm.event.Title)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{class:{
          'white--text': _vm.event.LandingPageTextColor == 'light'
        }},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.event.MinPrice))+" "),(_vm.event.MaxPrice > _vm.event.MinPrice)?_c('span',[_vm._v(" - "+_vm._s(_vm._f("toCurrency")(_vm.event.MaxPrice)))]):_vm._e()])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-divider',{staticClass:"my-2"}),(_vm.event.EventID !== 9376)?_c('v-row',[(_vm.event.Producer)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.event.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.event.LandingPageTextColor == 'light'
            }},[_vm._v(" Presented By ")]),_c('div',{class:{
              'white--text': _vm.event.LandingPageTextColor == 'light',
              'my-2': true
            }},[_vm._v(" "+_vm._s(_vm.event.Producer)+" ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.event.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.event.LandingPageTextColor == 'light'
            }},[_vm._v(" Location ")]),_c('div',{class:{
              'white--text': _vm.event.LandingPageTextColor == 'light',
              'my-2': true
            }},[_vm._v(" "+_vm._s(_vm.event.VenueName)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.event.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.event.LandingPageTextColor == 'light'
            }},[_vm._v(" Date ")]),_c('div',{class:{
              'white--text': _vm.event.LandingPageTextColor == 'light',
              'my-2': true
            }},[_vm._v(" "+_vm._s(_vm.event.minDate)+" ")])])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"depressed":"","to":_vm.event.BookingUrl,"color":"primary"}},[_vm._v("Select")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }