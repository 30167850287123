<template>
  <v-sheet class="pa-4" :color="event.LandingPageColor" width="80%">
    <v-row>
      <v-col cols="12" md="9">
        <h2 :class="{ 'white--text': event.LandingPageTextColor == 'light' }">
          {{ event.Title }}
        </h2>
      </v-col>
      <v-col cols="12" md="3">
        <div
          :class="{
            'white--text': event.LandingPageTextColor == 'light'
          }"
        >
          {{ event.MinPrice | toCurrency }}
          <span v-if="event.MaxPrice > event.MinPrice">
            - {{ event.MaxPrice | toCurrency }}</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="9">
        <v-divider class="my-2"></v-divider>
        <v-row v-if="event.EventID !== 9376">
          <v-col cols="12" md="4" v-if="event.Producer">
            <div
              :class="{
                'tempo-subtitle': event.LandingPageTextColor != 'light',
                'tempo-subtitle-light': event.LandingPageTextColor == 'light'
              }"
            >
              Presented By
            </div>
            <div
              :class="{
                'white--text': event.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              {{ event.Producer }}
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div
              :class="{
                'tempo-subtitle': event.LandingPageTextColor != 'light',
                'tempo-subtitle-light': event.LandingPageTextColor == 'light'
              }"
            >
              Location
            </div>
            <div
              :class="{
                'white--text': event.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              {{ event.VenueName }}
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div
              :class="{
                'tempo-subtitle': event.LandingPageTextColor != 'light',
                'tempo-subtitle-light': event.LandingPageTextColor == 'light'
              }"
            >
              Date
            </div>
            <div
              :class="{
                'white--text': event.LandingPageTextColor == 'light',
                'my-2': true
              }"
            >
              {{ event.minDate }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn depressed :to="event.BookingUrl" color="primary">Select</v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  props: ["event"]
};
</script>
<style scoped>
.tempo-subtitle {
  color: #919699;
  font-size: 15px;
}
.tempo-subtitle-light {
  color: #d3e3eb;
  font-size: 15px;
}
.tempo-badge {
  color: #0b5289;
}
</style>
