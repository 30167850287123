<template>
  <v-container>
    <v-form v-model="valid" ref="form">
      <v-row class="mt-4" justify="center">
        <error-message :message="errorMessage"></error-message>
      </v-row>
      <h3 class="mx-2 my-6">Ticket Holder</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            color="black"
            label="First Name (required)"
            :rules="[rules.required]"
            v-model="TicketHolderFirstName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            color="black"
            label="Last Name (required)"
            :rules="[rules.required]"
            v-model="TicketHolderLastName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            outlined
            color="black"
            label="Email (required)"
            :rules="[rules.required]"
            v-model="Email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="question of questions"
          :key="question.QuestionID"
        >
          <div v-if="question.Type == 2">
            <text-input
              :label="question.Text"
              v-model="order.responses[question.QuestionID]"
            ></text-input>
          </div>
          <div v-if="question.Type == 3">
            <v-textarea
              outlined
              color="black"
              auto-grow
              :label="question.Text"
              v-model="order.responses[question.QuestionID]"
            ></v-textarea>
          </div>
          <div v-if="question.Type === 5">
            <div class="body-text-2">{{ question.Text }}</div>
            <v-checkbox
              v-for="response of question.Responses"
              :key="response.ResponseID"
              v-model="order.responses[question.QuestionID]"
              :label="response.Text"
              :value="response.ResponseID"
              dense
            ></v-checkbox>
          </div>
          <div v-if="question.Type === 100">
            <v-text-field
              outlined
              color="black"
              :label="question.Text"
              v-model="order.responses[question.QuestionID]"
            ></v-text-field>
          </div>
          <div v-if="question.Type === 101">
            <v-text-field
              outlined
              color="black"
              :label="question.Text"
              v-model="order.responses[question.QuestionID]"
              type="number"
            ></v-text-field>
          </div>
          <div v-if="question.Type === 103">
            <state-input
              v-model="order.responses[question.QuestionID]"
            ></state-input>
          </div>
          <div v-if="question.Type === 104">
            <postal-code-input
              :label="question.Text"
              v-model="order.responses[question.QuestionID]"
            ></postal-code-input>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="my-4">
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          class="ma-2 white--text"
          @click="process"
          depressed
        >
          Continue
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from "vuex";
import { rules } from "@/validation";
import ErrorMessage from "@/components/shared/ErrorMessage";
import StateInput from "./includes/StateInput.vue";
import TextInput from "./includes/TextInput.vue";
import PostalCodeInput from "./includes/PostalCodeInput.vue";

export default {
  components: {
    ErrorMessage,
    StateInput,
    PostalCodeInput,
    TextInput
  },
  props: ["questions"],
  data() {
    return {
      rules,
      loading: false,
      errorMessage: "",
      valid: false
    };
  },
  computed: {
    ...mapState({
      order: state => state.order,
      user: state => state.user
    }),
    ...{
      TicketHolderFirstName: {
        get() {
          return this.$store.state.order?.TicketHolderFirstName;
        },
        set(value) {
          this.$store.commit("UPDATE_ORDER", { TicketHolderFirstName: value });
        }
      },
      TicketHolderLastName: {
        get() {
          return this.$store.state.order?.TicketHolderLastName;
        },
        set(value) {
          this.$store.commit("UPDATE_ORDER", { TicketHolderLastName: value });
        }
      },
      Email: {
        get() {
          return this.$store.state.order?.Email;
        },
        set(value) {
          this.$store.commit("UPDATE_ORDER", { Email: value });
        }
      }
    }
  },
  methods: {
    process() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.errorMessage = "Please correct errors before continuing";
        this.loading = false;
        return;
      }
      this.$store.commit("UPDATE_ORDER", { ...this.order });
      if (this.user?.sub) {
        this.$router.push("payment");
        return;
      }

      // This will always return an error. We can tell by the error code if the user exists or not.
      Auth.signIn(this.Email, "jdklj;ajs;fd;a")
        .then(() => {})
        .catch(error => {
          if (error.code === "UserNotFoundException") {
            this.$router.push("accountNotFound");
          } else {
            this.$router.push("accountFound");
          }
        });
    }
  }
};
</script>
