import Client from "./Client";
import { formatEventsInList } from "@/services/EventFormatter";

export async function findEvent(codeOrEventId) {
  const httpClient = new Client();
  const response = await httpClient.get(`events/${codeOrEventId}`);
  return response.data;
}

export async function findEventsByCustomer(codeOrCustomerId) {
  const httpClient = new Client();
  const response = await httpClient.get(
    `events/by-customer/${codeOrCustomerId}`
  );
  return formatEventsInList(response.data);
}

export async function findCustomizationsByEvent(eventId) {
  const httpClient = new Client();
  const response = await httpClient.get(`configuration/event/${eventId}`);
  return response.data?.customJson ? JSON.parse(response.data?.customJson) : {};
}
