<template>
  <v-container>
    <v-row class="mt-5" align="center" style="margin: 0">
      <v-col cols="2">
        <div class="body-2">Order Completed</div>
      </v-col>
      <v-col cols="2">
        <div class="body-2">Order Total</div>
      </v-col>
      <v-col cols="2">
        <div class="body-2">Event Name</div>
      </v-col>
      <v-col cols="2">
        <div class="body-2">Event Date</div>
      </v-col>
      <v-col cols="2">
        <div class="body-2">Number of Tickets</div>
      </v-col>
    </v-row>
    <v-card class="my-2" v-for="event in events" :key="event.id">
      <v-row align="center" style="margin: 0">
        <v-col cols="2">
          <div class="body-1">{{ event.completedOrder }}</div>
        </v-col>
        <v-col cols="2">
          <div class="body-1">{{ event.orderCount }}</div>
        </v-col>
        <v-col cols="2">
          <div class="body-1">{{ event.eventName }}</div>
        </v-col>
        <v-col cols="2">
          <div class="body-1">{{ event.eventDate }}</div>
        </v-col>
        <v-col cols="2">
          <div class="body-1">{{ event.ticketNumbers }} Tickets</div>
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" depressed v-on:click="viewTickets"
            >View Ticket(s)</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
const data = [
  {
    id: 1,
    completedOrder: "10/09/2021 13:25",
    orderCount: 3,
    eventName: "2021 Lakefront Music Fest 2-Night",
    eventDate: "12/29/2021",
    ticketNumbers: 1
  },
  {
    id: 2,
    completedOrder: "10/09/2021 13:25",
    orderCount: 3,
    eventName: "2021 Loppet Nordic Race",
    eventDate: "12/29/2021",
    ticketNumbers: 3
  }
];
export default {
  name: "DashboardScreen",
  data() {
    return {
      events: data
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    viewTickets: function() {}
  }
};
</script>
