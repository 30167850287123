<template>
  <v-dialog v-model="display" width="600px" persistent>
    <v-card>
      <v-row justify="center" class="pt-8">
        <v-col cols="auto">
          <v-img
            class="justify-center"
            height="76px"
            width="74px"
            src="@/assets/not-found-icon.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-card-title class="primary--text" justify="center">
        <v-row justify="center">
          <v-col cols="auto">Tickets not on sale yet.</v-col>
        </v-row>
      </v-card-title>
      <!-- // !! disabled -->
      <!-- <v-card-text
        >Create an account to have the fastest checkout experience once this
        event launches.
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn depressed color="primary" to="/createAccount"
              >Create Account</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions> -->
      <v-card-text
        ><v-row justify="center">
          <v-col cols="auto">Event on sale {{ onSale }} </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["display", "onSale"]
};
</script>
