<template>
  <v-autocomplete
    outlined
    color="black"
    :items="validStates"
    hide-selected
    item-text="name"
    item-value="code"
    label="State (required)"
    :rules="[rules.required]"
    :value="value"
    @input="updateValue"
  ></v-autocomplete>
</template>
<script>
import { rules } from "@/validation";
import { getStates } from "@/repositories/AddressRepository";

export default {
  name: "state-input",
  props: ["value"],
  emits: ["input"],
  computed: {
    validStates() {
      return getStates();
    },
    rules() {
      return rules;
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
