<template>
  <v-container class="white pa-4">
    <v-row class="my-2 mx-8">
      <v-col cols="12" md="12">
        <timeout-message
          :expirySeconds="expirySeconds"
          @on-expired="setExpired"
        ></timeout-message>
        <event-header :event="event"></event-header>
        <checkout-steps step="1"></checkout-steps>
        <v-divider class="my-2"></v-divider>
      </v-col>
    </v-row>
    <v-row class="mt-4" justify="center">
      <error-message :message="errorMessage"></error-message>
    </v-row>
    <v-row class="my-2 mx-8">
      <v-col cols="12" md="12">
        <div class="text-body-1">
          It looks like you already have an account! Please Log In:
        </div>
        <forgotten-password-modal></forgotten-password-modal>
        <v-text-field
          label="Password (required)"
          outlined
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-btn color="primary" depressed v-on:click="login">Log In</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import ForgottenPasswordModal from "@/components/login/ForgottenPasswordModal";
import { mapState } from "vuex";
import CheckoutSteps from "./CheckoutSteps";
import EventHeader from "./EventHeader";
import ErrorMessage from "@/components/shared/ErrorMessage";
import TimeoutMessage from "./TimeoutMessage";
export default {
  components: {
    CheckoutSteps,
    ErrorMessage,
    EventHeader,
    TimeoutMessage,
    ForgottenPasswordModal
  },
  data() {
    return {
      password: "",
      showPassword: false,
      errorMessage: ""
    };
  },
  computed: mapState({
    order: state => state.order,
    event: state => state.event,
    expirySeconds: state =>
      new Date(state.inventoryResponse?.expires?.date + "Z") / 1000
  }),
  methods: {
    login() {
      this.errorMessage = "";
      Auth.signIn(this.order.Email, this.password)
        .then(response => {
          this.$store.commit("STORE_USER", {
            sub: response.username, // Cognito username is the sub (unique ID)
            username: this.username,
            firstName: response.attributes?.given_name,
            lastName: response.attributes?.family_name
          });
          this.$router.push("payment");
        })
        .catch(error => {
          console.log("ERROR", error);

          // this.showCreateAccount = error.code === "UserNotFoundException";
          this.errorMessage = error?.message;
        });
    },
    setExpired() {
      this.$store.commit("REMOVE_EVENT");
      this.$router.push("expired");
    }
  }
};
</script>
