<template>
  <v-text-field
    outlined
    color="black"
    :label="label"
    type="number"
    :value="value"
    @input="updateValue"
  ></v-text-field>
</template>
<script>
export default {
  name: "postal-code-input",
  props: ["label", "value"],
  emits: ["input"],
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
