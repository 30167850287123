<template>
  <v-container class="white pa-4">
    <v-row class="my-2 mx-8">
      <v-col cols="12" md="12">
        <timeout-message
          :expirySeconds="expirySeconds"
          @on-expired="setExpired"
        ></timeout-message>
        <event-header :event="event"></event-header>
        <checkout-steps step="1"></checkout-steps>
        <v-divider class="my-2"></v-divider>

        <ticketing-questions :questions="event.Questions"></ticketing-questions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import CheckoutSteps from "./CheckoutSteps";
import EventHeader from "./EventHeader";
import TimeoutMessage from "./TimeoutMessage";
import TicketingQuestions from "./TicketingQuestions";

export default {
  components: {
    CheckoutSteps,
    EventHeader,
    TicketingQuestions,
    TimeoutMessage
  },
  computed: mapState({
    event: state => state.event,
    customer: state => state.customer,
    expirySeconds: state =>
      new Date(state.inventoryResponse?.expires?.date + "Z") / 1000
  }),
  methods: {
    setExpired() {
      this.$store.commit("REMOVE_EVENT");
      this.$router.push("expired");
      window.location.reload();
    }
  },
  created() {
    if (
      !this.expirySeconds ||
      this.expirySeconds < Math.floor(Date.now() / 1000)
    ) {
      this.$store.commit(
        "STORE_CART_EXPIRY",
        Math.floor(Date.now() / 1000) + 1200
      );
    }
    if (this.event.FacebookPixelID) {
      this.$analytics.fpq.event("PageLoad", this.$route.path);
    }
  }
};
</script>
