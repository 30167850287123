<template>
  <v-dialog v-model="display" width="600px" persistent>
    <v-card>
      <v-row justify="center" class="pt-8">
        <v-col cols="auto">
          <v-img
            class="justify-center"
            height="76px"
            width="74px"
            src="@/assets/not-found-icon.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-card-title class="primary--text" justify="center">
        <v-row justify="center">
          <v-col cols="auto">This event has come and gone.</v-col>
        </v-row>
      </v-card-title>
      <v-card-text>But you can find other upcoming events here: </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn depressed outlined color="primary" :to="listingUrl">
              Tickets for {{ customerName }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["display", "customerName", "listingUrl"]
};
</script>
