import Client from "./Client";

export async function storePendingOrder(inventoryId, data) {
  // Upload to /orders api endpoint as json string
  const body = {
    inventory_id: inventoryId,
    order_data: JSON.stringify(data)
  };
  try {
    const httpClient = new Client();
    const response = await httpClient.post("/order", {
      body
    });
    return response.status === 200;
  } catch (err) {
    console.log(err.response);
    return false;
  }
}

export async function sendConfirmationEmail(orderId) {
  const body = {
    orderid: orderId
  };

  try {
    const httpClient = new Client();
    const response = await httpClient.post("/mail/orderconfirmation", {
      body
    });
    return response.status === 200;
  } catch (err) {
    console.log(err.response);
    return false;
  }
}
