<template>
  <div class="c_background header--container" v-if="isVisible">
    <div class="header--left-fill"></div>
    <div class="header u_contain_floated_children">
      <div class="header--left">
        <v-img
          src="@/assets/tempo-logo-white.png"
          contain
          max-width="120"
          max-height="24"
        ></v-img>
      </div>

      <div class="header--links-container">
        <div class="header--links-flag"></div>
        <div class="header--links-left">
          <div
            v-for="link of links"
            :key="link.text"
            class="header--link-container"
          >
            <div class="header--left-link-flag"></div>
            <div class="header--link-text">
              <a class="header--link" :href="link.url">{{ link.text }}</a>
            </div>
            <div class="header--right-link-flag"></div>
          </div>
        </div>
        <div class="header--links-right" style="padding-right: 15%">
          <cart-popup></cart-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWindowSize } from "@/services/WindowSize";
import CartPopup from "@/components/CartPopup";
export default {
  components: { CartPopup },
  data() {
    return {
      links: [
        {
          text: "TICKETS",
          url: "https://www.tempotickets.com/tickets"
        },
        {
          text: "REGISTRATION",
          url: "https://www.tempotickets.com/registration"
        },
        {
          text: "CONTACT",
          url: "https://www.tempotickets.com/contact"
        },
        {
          text: "HELP",
          url: "https://www.tempotickets.com/help"
        }
      ]
    };
  },
  computed: {
    isVisible() {
      return (
        !this.$route.path.startsWith("/ticket") && getWindowSize().width > 800
      );
    }
  }
};
</script>

<style scoped>
.header--container {
  border-bottom: 2px solid #f26531;
  position: relative;
}

.header--left-fill {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
  background-color: #ade0ed;
  z-index: 0;
}

.header {
  position: relative;
  background-color: #ade0ed;
  z-index: 1;
}

.header--left {
  position: relative;
  padding: 20px;
  text-align: center;
}

.header--left img {
  height: 24px;
}

.header--links-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 149px;
  background: #fff;
}

.header--links-left {
  float: left;
  position: relative;
}

.header--link-container {
  float: left;
  position: relative;
}

.header--link-container:hover {
  color: #ffffff;
  background-color: #f26531;
}

.header--link-container:hover .header--left-link-flag {
  border-right-color: #f26531;
}

.header--link-container:hover .header--right-link-flag,
.header--link-container:hover
  + .header--link-container
  .header--left-link-flag {
  border-top-color: #f26531;
  border-bottom-color: #f26531;
}

.header--link-container:hover .header--link {
  color: #ffffff;
}

.header--left-link-flag,
.header--right-link-flag {
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  border-top: 32px solid #ffffff;
  border-right: 32px solid #ffffff;
  border-bottom: 32px solid #ffffff;
}

.header--left-link-flag {
  left: 0;
}

.header--link-container:first-child .header--left-link-flag {
  border-top-color: #ade0ed;
  border-bottom-color: #ade0ed;
}

.header--right-link-flag {
  left: 100%;
}

.header--link-text {
  margin-left: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  height: 100%;
  line-height: 24px;
}

.header--link,
.header--admin-link,
.header--menu--link,
.header--menu--admin-link {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 14px;
  font-weight: 500;
}

.header--link {
  color: #505050;
}

.header--link:hover {
  text-decoration: none;
}

.header--admin-link,
.header--menu--admin-link {
  font-weight: 600;
}

.header--links-right {
  float: right;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 24px;
  line-height: 24px;
}

.header--admin-link {
  margin-left: 32px;
}

.header--admin-link:first-child {
  margin-left: 0;
}

.header--menu {
  background-color: #ffffff;
  border-bottom: 2px solid #f26531;
  padding: 20px;
}

.header--menu--links-container {
  margin-top: -10px;
  margin-left: -30px;
  text-align: center;
}

.header--menu--link,
.header--menu--admin-link {
  display: inline-block;
  margin-left: 30px;
  margin-top: 10px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.header--menu--link {
  color: #505050;
}
</style>
