import Vue from "vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import parse from "date-fns/parse";
import aws_exports from "./aws-exports";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./state/store";
import VueFacebookPixel from "vue-facebook-pixel";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue2TouchEvents from "vue2-touch-events";
import { format } from "date-fns";

Amplify.configure(aws_exports);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.config.productionTip = false;
Vue.use(VueFacebookPixel);
Vue.use(Vue2TouchEvents);

Vue.filter("toCurrency", function(value) {
  const digits = value % 100 === 0 ? 0 : 2;
  // currency is passed in using cents.
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: digits
  });
  return formatter.format(value / 100);
});

Vue.filter("toDateWithTime", function(value) {
  return format(
    parse(value, "yyyy-MM-dd HH:mm", new Date()),
    "EEE MMM d @ h:mma"
  );
});

Sentry.init({
  Vue,
  enabled: false,
  dsn:
    "https://78ba2b87713ddd21ed475c302305a5ce@o4506182033801216.ingest.us.sentry.io/4507399715094528",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
  environment: process.env.NODE_ENV
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
