<template>
  <v-container max-width="$vuetify.breakpoint.lg" class="page">
    <v-row class="ticket_cut_out">
      <v-col sm="3" class="qr-code">
        <div class="text-center">
          <h2>Order #{{ ticket.orderId }}</h2>
        </div>
        <div class="text-center">
          <qrcode-vue :value="ticket.barcode" size="200" level="H" />
        </div>

        <div class="text-center">{{ readableBarcode }}</div>
      </v-col>
      <v-col sm="9" class="pt-4 pr-4 ticket-info">
        <div class="float-right pr-2 pt-2">{{ index }} of {{ total }}</div>
        <div class="email-ticket mb-2 float-md-right pr-4">
          <v-btn v-if="total > 1" :href="createEmailHref" color="primary"
            >Send Ticket Via Email</v-btn
          >
        </div>
        <h3 v-html="ticket.eventName"></h3>
        <p class="mb-0" v-if="ticket.eventName != 'Festival Guidebook'">
          {{ ticket.eventDate }}
        </p>
        <p class="py-2 mb-0">{{ ticket.ticketType }}</p>
        <p class="py-2" v-if="ticket.specialNotes">
          <span v-html="ticket.specialNotes"></span>
        </p>
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item
                class="list_item"
                dense
                v-if="ticket.eventName != 'Festival Guidebook'"
              >
                <v-list-item-icon class="my-0 align-self-center">
                  <v-icon>mdi-map-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="list_title"
                    v-text="ticket.venueName"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="list_item">
                <v-list-item-icon class="my-0 align-self-center">
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="list_title"
                    v-text="ticket.customerEmail"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="list_item" v-if="ticket.customerPhone">
                <v-list-item-icon class="my-0 align-self-center">
                  <v-icon>mdi-phone-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="list_title"
                    v-text="ticket.customerPhone"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="list_item">
                <v-list-item-icon class="my-0 align-self-center">
                  <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="list_title"
                    v-text="ticket.customerWebsite"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="mt-3">
            <div v-for="response in ticket.responses" :key="response.Question">
              {{ response.Question }}: {{ response.Answer }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <span class="text-uppercase font-weight-bold">This is your Ticket</span>
        <div class="float-right">
          ticketing by <span class="font-weight-bold"> Tempo </span>
          • tempotickets.com
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-4">
      <img
        v-if="ticket.eventTicketImage"
        class="print-view-image"
        :src="
          `https://www.tempotickets.com/tempotickets/file_store/${ticket.eventTicketImage}`
        "
      />
    </v-row>
    <v-row class="ma-4">
      <v-col cols="12" md="12">
        <h5 class="text-h5 ticket-section-heading">Location</h5>
        <div class="venue-info">
          <span class="font-weight-bold">{{ ticket.venueName }} - </span>
          {{ ticket.venueAddress }}, {{ ticket.venueCity }},
          {{ ticket.venueState }}
        </div>
        <div class="parking-div">Parking: {{ ticket.venueParkingInfo }}</div>
      </v-col>
      <v-col cols="12" md="12">
        <h5 class="text-h5 ticket-section-heading">Using Your Ticket</h5>
        <ul>
          <li>
            Bring this ticket to the event, where the barcode will be scanned
            for admission.
          </li>
          <li>
            Only one admission allowed per valid barcode. Duplicates will not be
            accepted. Protect your ticket! Do not allow it to be duplicated.
          </li>
          <li>
            If lost or stolen, this ticket may be reissued with a new barcode.
            (See contact details below.) Lost tickets may also be claimed at the
            event (photo ID required).
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="12">
        <h5 class="text-h5 ticket-section-heading">Contact</h5>
        <div>
          <p class="float-left mr-3 mb-2 font-weight-bold">
            {{ ticket.customerName }}
          </p>
          <p v-if="ticket.customerEmail" class="float-left mr-3 mb-2">
            <v-icon class="contact-icon mr-1" color="black">mdi-email</v-icon
            >{{ ticket.customerEmail }}
          </p>
          <p v-if="ticket.customerPhone" class="float-left mr-3 mb-2">
            <v-icon class="contact-icon mr-1" color="black">mdi-phone</v-icon
            >{{ formatPhoneNumber }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "printable-ticket",
  components: {
    QrcodeVue
  },
  props: ["ticket", "index", "total"],
  computed: {
    readableBarcode() {
      const code = this.ticket.barcode;
      if (code?.length < 6) {
        // usually bar codes are 8 digits but if that changes we don't want substring errors.
        return code;
      }
      return (
        code.substring(0, 3) +
        " " +
        code.substring(3, 6) +
        " " +
        code.substring(6)
      );
    },
    createEmailHref() {
      return `mailto:?subject=${this.ticket.eventName}&body=Here is the url to access your ticket: ${window.location.origin}${this.$route.fullPath}/${this.ticket.barcode}`;
    },
    formatPhoneNumber() {
      const cleaned = ("" + this.ticket.customerPhone).replace(/\D/g, "");
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
      }
      return null;
    }
  },
  methods: {}
};
</script>
<style scoped>
.contact-icon {
  vertical-align: baseline;
  font-size: 16px;
}
.print-view-image {
  max-height: 200px;
  max-width: 100%;
}
.ticket_cut_out {
  border: 2px dashed;
  margin: 10px;
  background-color: white;
}
.list_item {
  min-height: unset;
}
.parking-div {
  white-space: pre;
}
.venue-info {
  font-size: large;
}
.ticket-section-heading {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 3px double;
  font-size: 12pt;
  font-weight: bold;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
@media print {
  .email-ticket {
    display: none;
  }
  .page {
    break-after: page;
  }
  .list_title {
    white-space: unset;
  }
  .qr-code {
    flex: unset;
    width: unset;
    max-width: unset;
  }
  .ticket-info {
    max-width: unset;
  }
}
</style>
