<template>
  <v-container class="white pa-4">
    <v-alert type="info">Your cart has expired.</v-alert>
    <v-row class="mx-2 my-2" justify="center">
      <v-btn
        v-if="this.customer.CustomerListingUrl"
        @click="goToEvent"
        depressed
        color="primary"
        >Return to Event Listing</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    customer: state => state.customer
  }),
  methods: {
    goToEvent() {
      this.$router.push(this.customer.CustomerListingUrl);
    }
  }
};
</script>
