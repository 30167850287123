import Client from "./Client";

export async function makePayment(input) {
  const httpClient = new Client();
  const Responses = Object.keys(input.responses).map(k => {
    const keys = k.split("_");
    return {
      TicketNumber: keys[0],
      TicketTypeID: keys[1],
      QuestionID: keys[2],
      value: input.responses[k]
    };
  });
  const body = {
    BillingFirstName: input.BillingFirstName,
    BillingLastName: input.BillingLastName,
    BillingAddress: input.BillingAddress,
    BillingAddress2: input.BillingAddress2,
    BillingCity: input.BillingCity,
    BillingState: input.BillingState,
    BillingZip: input.BillingZip,
    BillingCountry: input.BillingCountry,
    BillingPhone: input.BillingPhone,
    BillingEmail: input.BillingEmail,
    Capture: input.capture || false,
    StripeToken: input.stripeToken,
    StripePaymentIntentID: input.stripePaymentIntentId,
    StripePaymentSecret: input.paymentSecret,
    CreditCard: input.token,
    PaymentAmount: input.PaymentAmount,
    InventoryID: input.inventoryId,
    DonationAmount: input.donation,
    Tickets: input.tickets,
    Responses
  };
  return httpClient.post("billing/checkout", { body });
}

// export async function makePayment(input) {
//   input; // avoids compile error
//   const response = await axios.get(
//     "https://api.mockaroo.com/api/295a1c10?count=1&key=8ee196f0"
//   );
//   // axios.post('TODO', {body: input})
//   return response.data[0];
// }
