<template>
  <v-row>
    <v-dialog v-model="dialog" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="end" class="mb-1">
          <v-btn color="primary" plain v-bind="attrs" v-on="on"
            >Forgot Password?</v-btn
          >
        </v-row>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Password Reset</span>
        </v-card-title>
        <v-card-text>
          <error-message :message="errorMessage"></error-message>

          <v-text-field
            :readonly="requestSent && email !== undefined"
            color="black"
            outlined
            label="Email Address"
            v-model="email"
          ></v-text-field>
          <v-container v-if="requestSent">
            <v-alert type="success"
              >A password reset code has been sent to your email. Please enter
              that code here.</v-alert
            >
            <v-text-field
              color="black"
              outlined
              label="Verification Code"
              v-model="code"
            ></v-text-field>
            <v-text-field
              label="Password"
              outlined
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.passwordLength]"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              label="Confirm Password"
              outlined
              v-model="password2"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[rules.passwordLength]"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!requestSent"
            color="primary"
            depressed
            outlined
            @click="requestSent = true"
          >
            I already have a Reset Code
          </v-btn>
          <v-btn
            v-if="!requestSent"
            color="primary"
            depressed
            @click="requestReset"
          >
            Send Me a Password Reset Code
          </v-btn>
          <v-btn v-if="requestSent" color="primary" depressed @click="reset">
            Reset My Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Auth } from "aws-amplify";
import ErrorMessage from "../shared/ErrorMessage";
import { rules } from "../../validation";
export default {
  components: {
    ErrorMessage
  },
  props: ["email"],
  data() {
    return {
      dialog: false,
      requestSent: false,
      errorMessage: "",
      showPassword: false,
      password: "",
      password2: "",
      code: "",
      rules
    };
  },
  methods: {
    requestReset() {
      Auth.forgotPassword(this.email)
        .then(() => (this.requestSent = true))
        .catch(
          () =>
            (this.errorMessage =
              "There was a problem sending your request.  Please check your email address and try again.")
        );
    },
    reset() {
      if (this.password === this.password2) {
        Auth.forgotPasswordSubmit(this.email, this.code, this.password)
          .then(() => (this.dialog = false))
          .catch(err => (this.errorMessage = err?.message));
      }
    }
  }
};
</script>
