<template>
  <div class="text-right">
    <div :key="index" class="primary--text">
      <span v-if="max && quantity >= max"
        >(Maximum of {{ max }} per order)</span
      >
      <span v-else-if="min && quantity === min"
        >(Minumum of {{ min }} per order)</span
      >

      {{ quantity }}
      <span v-if="quantity === 1">{{ name }}</span
      ><span v-else>{{ name }}s</span>
      {{ totalPrice | toCurrency }}
    </div>
    <div>
      <v-btn
        outlined
        color="primary"
        @click="decreaseQuantity()"
        :disabled="quantity === 0 || !quantity"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn
        outlined
        color="primary"
        @click="increaseQuantity()"
        :disabled="max !== undefined && quantity >= max"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: ["index", "max", "min", "quantity", "name", "price"],
  methods: {
    increaseQuantity() {
      if (this.min && this.quantity < this.min) {
        // Some ticket types require a minumum amount of tickets.  In that case we
        //  need to skip over the quantities smaller than the minumum.
        this.$emit("onQuantityChange", this.min, this.index);
      } else {
        this.$emit(
          "onQuantityChange",
          this.quantity ? this.quantity + 1 : 1,
          this.index
        );
      }
    },
    decreaseQuantity() {
      if (this.min && this.quantity === this.min) {
        // Some ticket types require a minumum amount of tickets.  In that case we
        //  need to skip over the quantities smaller than that and set to 0.
        this.$emit("onQuantityChange", 0, this.index);
      } else {
        this.$emit("onQuantityChange", this.quantity - 1, this.index);
      }
    }
  },
  computed: {
    totalPrice() {
      return this.quantity ? this.quantity * this.price : 0;
    }
  }
};
</script>
