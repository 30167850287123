<template>
  <v-alert color="accent" width="80%">
    <template v-slot:prepend>
      <v-icon color="primary">mdi-clock</v-icon>
    </template>
    <span v-if="expired" style="color: #f22632" class="mx-4"
      >Time to complete your order has expired.</span
    >
    <span v-else style="color: #f22632" class="mx-4"
      >{{ expiresMin }} minutes, {{ expiresSec }} seconds remain to complete
      your order.</span
    >
  </v-alert>
</template>

<script>
export default {
  name: "TimeoutMessage",
  data() {
    return {
      expiresSec: 0,
      expiresMin: 0,
      countDown: null,
      expired: false
    };
  },
  props: ["expirySeconds"],
  methods: {
    startCountDown() {
      this.countDown = setInterval(() => {
        const totSeconds = this.expirySeconds - Math.floor(Date.now() / 1000);
        if (totSeconds <= 0) {
          this.expired = true;
          this.$emit("on-expired");
        } else {
          this.expiresSec = totSeconds % 60;
          this.expiresMin = Math.floor(totSeconds / 60);
        }
      }, 1000);
    }
  },
  created() {
    this.startCountDown();
  },
  beforeDestroy() {
    clearInterval(this.countDown);
  }
};
</script>
