import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#F26632",
        secondary: "#B7DFEB",
        accent: "#FFE380",
        error: "#F26632",
        red: "#FF5252"
      }
    }
  }
});
