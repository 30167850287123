/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id:
    "us-west-2:88c7ad1f-17a3-48ad-b4d1-d39218b5683e",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_2L2Jcoled",
  aws_user_pools_web_client_id: "19vv5tk76rpsft38vj2ojn4pmf",
  oauth: {}
};

export default awsmobile;
