<template>
  <div>
    <v-card class="mx-auto my-12" max-width="500">
      <v-card-title> Log In </v-card-title>
      <v-card-text>
        <v-alert type="warning" v-if="errorMessage" class="mb-12">
          <template v-slot:prepend>
            <v-icon color="primary">mdi-alert</v-icon>
          </template>
          <span style="color: #f22632" class="mx-4">{{ errorMessage }}</span>
          <a v-if="showCreateAccount" href="/#/createAccount">Create Account</a>
        </v-alert>

        <v-row class="px-2 mx-2">
          <v-text-field
            label="Email Address (required)"
            outlined
            v-model="username"
          ></v-text-field>
        </v-row>
        <forgotten-password-modal></forgotten-password-modal>
        <v-row d-flex class="px-2 mx-2">
          <v-text-field
            label="Password (required)"
            outlined
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-subtitle class="px-2 mx-2">
        Your use of our Service is governed by the terms of our Customer
        Agreement.
      </v-card-subtitle>
      <v-card-actions>
        <v-row align="center" justify="space-around">
          <v-btn color="primary" depressed v-on:click="login">Log In</v-btn>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <span>TODO: Custom Text</span>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import ForgottenPasswordModal from "./ForgottenPasswordModal";
export default {
  components: { ForgottenPasswordModal },
  data: function() {
    return {
      username: "",
      password: "",
      showPassword: false,
      errorMessage: "",
      showCreateAccount: false
    };
  },
  methods: {
    login: function() {
      this.errorMessage = "";
      Auth.signIn(this.username, this.password)
        .then(response => {
          this.$store.commit("STORE_USER", {
            sub: response.username, // Cognito username is the sub (unique ID)
            username: this.username,
            firstName: response.attributes?.given_name,
            lastName: response.attributes?.family_name,
            token: response.signInUserSession?.accessToken?.jwtToken
          });
          this.$router.push("/dashboard");
        })
        .catch(error => {
          this.showCreateAccount = error.code === "UserNotFoundException";
          this.errorMessage = error?.message;
        });
    }
  }
};
</script>
