<template>
  <v-sheet max-width="750">
    <v-row v-for="line in lines" :key="line.packageId || line.ticketId">
      <v-sheet width="100%" class="pa-4">
        <v-row>
          <v-col md="12"
            ><h2>{{ line.title }}</h2></v-col
          >
        </v-row>
        <v-row>
          <v-col md="4">
            <v-btn
              class="ma-2"
              depressed
              outlined
              :to="line.path"
              v-if="showEditButton"
              >Edit</v-btn
            ><v-btn
              depressed
              outlined
              @click="
                line.packageId
                  ? deletePackage(line.packageId)
                  : deleteTicket(line.eventId, line.ticketId)
              "
              v-if="showEditButton"
              >Delete</v-btn
            >
          </v-col>
          <v-col md="5"
            >{{ line.quantity }} X {{ line.description }}<br />
            {{ line.price | toCurrency }} +
            {{ line.serviceFee | toCurrency }} Service Fee
            <span v-if="line && line.appliedCouponCode"
              ><br />Discount Code: {{ line.appliedCouponCode }}</span
            >
          </v-col>
          <v-col md="3" class="payment-amount-text text-right">
            {{ line.totalPrice | toCurrency }}
          </v-col>
          <v-sheet v-if="line.tickets">
            <v-container v-for="ticket in line.tickets" :key="ticket.ticketId">
              <v-row v-for="n in ticket.quantity" :key="n">
                <v-col
                  v-for="question of ticket.questions"
                  :key="`${n}_${question.id}`"
                >
                  {{ question.text }}
                  <span v-if="question.type == 1"
                    ><v-select
                      outlined
                      color="black"
                      :value="value[`${n}_${ticket.ticketId}_${question.id}`]"
                      @change="
                        updateResponse(
                          `${n}_${ticket.ticketId}_${question.id}`,
                          $event
                        )
                      "
                      item-text="QuestionResponseText"
                      item-value="QuestionResponseID"
                      :items="question.responses"
                      :rules="[rules.required]"
                      dense
                    ></v-select
                  ></span>
                  <span v-if="question.type != 1">
                    <text-input
                      label="Response"
                      :value="value[`${n}_${ticket.ticketId}_${question.id}`]"
                      :rules="[rules.required]"
                      @input="
                        updateResponse(
                          `${n}_${ticket.ticketId}_${question.id}`,
                          $event
                        )
                      "
                    ></text-input>
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
          <v-sheet v-if="line.questions">
            <v-row v-for="n in line.quantity" :key="n">
              <v-col v-for="question of line.questions" :key="question.id">
                {{ question.text }}
                <span v-if="question.type == 1"
                  ><v-select
                    outlined
                    color="black"
                    :value="value[`${n}_${question.id}`]"
                    @change="updateResponse(`${n}_${question.id}`)"
                    item-text="QuestionResponseText"
                    item-value="QuestionResponseID"
                    :items="question.responses"
                    dense
                  ></v-select
                ></span>
                <span v-if="question.type != 1">
                  <text-input
                    label="Response"
                    :value="value[`${n}_${question.id}`]"
                    @input="updateResponse(`${n}_${question.id}`)"
                  ></text-input>
                </span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-row>
      </v-sheet>
    </v-row>
    <v-row>
      <v-col md="12" class="payment-total-text text-right"
        >Total: {{ total | toCurrency }}</v-col
      >
    </v-row>
  </v-sheet>
</template>

<script>
import { rules } from "@/validation";
import { mapGetters, mapState } from "vuex";
import TextInput from "./includes/TextInput";
export default {
  props: {
    value: Object,
    showEditButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TextInput
  },
  data() {
    return {
      responses: {},
      rules: rules
    };
  },
  computed: {
    ...mapState({
      event: state => state.event,
      appliedCoupon: state => state.appliedCoupon
    }),
    ...mapGetters({
      lines: "getCartLines",
      total: "getOrderTotal"
    })
  },
  methods: {
    deletePackage(packageId) {
      this.$store.dispatch("REMOVE_PACKAGE_ORDER", packageId);
    },
    deleteTicket(eventId, ticketId) {
      this.$store.dispatch("REMOVE_EVENT_TICKET", {
        EventID: eventId,
        TicketID: ticketId
      });
    },
    updateResponse(key, response) {
      this.$emit("input", { ...this.value, [key]: response });
    }
  }
};
</script>

<style scoped>
.payment-total-text {
  color: #f26632;
  font-size: 24px;
}
.payment-amount-text {
  font-size: 24px;
}
</style>
