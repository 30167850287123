<template>
  <v-form v-model="valid" ref="form">
    <v-container class="white pa-4">
      <v-row justify="center" class="my-4">
        <timeout-message
          :expirySeconds="expirySeconds"
          @on-expired="setExpired"
        ></timeout-message>
      </v-row>
      <v-row class="my-4 mx-8">
        <v-col cols="12" md="12">
          <event-header
            :event="event"
            :show-return-button="false"
            :show-event-name="false"
          ></event-header>
          <cart-items v-model="input.responses"></cart-items>
        </v-col>
      </v-row>
      <donately :campaign="donationCampaign"></donately>
      <v-row
        v-if="event && event.Coupons && event.Coupons.length > 0"
        justify="start"
      >
        <v-col cols="12" md="2">
          <v-text-field
            outlined
            dense
            color="black"
            label="Discount Code"
            v-model="input.DiscountCode"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-btn
            test
            color="#de6139"
            @click="applyDiscount"
            class="white--text mb-2"
            >Apply</v-btn
          >
        </v-col>
        <v-col cols="12" md="3">
          <v-alert v-if="this.discountErrorMessage" dense text type="warning">{{
            this.discountErrorMessage
          }}</v-alert>
        </v-col>
      </v-row>
      <!-- <div>{{ event.Coupons }}</div> -->
      <!-- <div>{{ event }}</div> -->

      <v-divider class="mb-4"></v-divider>
      <v-row justify="space-between">
        <!--        <v-col md="3">-->
        <!--          <v-btn-->
        <!--            depressed-->
        <!--            color="#de6139"-->
        <!--            :to="customer.CustomerListingUrl"-->
        <!--            class="white&#45;&#45;text"-->
        <!--            >Shop more {{ customer.CustomerCategoryName }} events</v-btn-->
        <!--          >-->
        <!--        </v-col>-->
        <v-col md="3">
          <v-btn text color="#de6139" @click="cancelOrder">Cancel Order</v-btn>
        </v-col>
      </v-row>

      <v-container>
        <v-row class="mt-4" justify="center">
          <error-message :message="errorMessage"></error-message>
        </v-row>
        <h3 class="mx-2 my-6">Payment</h3>
        <v-row v-if="terminalEnabled">
          <v-col cols="12" md="3">
            <v-select
              outlined
              label="Terminal"
              hint="Select the terminal for payment"
              persistent-hint
              color="black"
              :value="terminalReaderSelected"
              @change="setReaderSelected($event)"
              item-text="label"
              item-value="id"
              :items="terminalReaders"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="terminalEnabled">
          <p><strong>Receipt Information</strong>:</p>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              color="black"
              type="email"
              :label="terminalEnabled ? 'Email (optional)' : 'Email (required)'"
              :rules="emailRules"
              v-model="input.BillingEmail"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              color="black"
              :label="
                terminalEnabled
                  ? 'First Name (optional)'
                  : 'First Name (required)'
              "
              v-model="input.BillingFirstName"
              :rules="requiredRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              outlined
              color="black"
              :label="
                terminalEnabled
                  ? 'Last Name (optional)'
                  : 'Last Name (required)'
              "
              :rules="requiredRules"
              v-model="input.BillingLastName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="terminalEnabled === false">
            <v-text-field
              dense
              outlined
              color="black"
              label="Phone (required)"
              :rules="requiredRules"
              v-model="input.BillingPhone"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!terminalEnabled">
          <v-col cols="12" md="4">
            <text-input
              label="Address Line 1 (required)"
              :rules="requiredRules"
              v-model="input.BillingAddress"
            >
            </text-input>
          </v-col>
          <v-col cols="12" md="2">
            <text-input
              label="City (required)"
              :rules="requiredRules"
              v-model="input.BillingCity"
            >
            </text-input>
          </v-col>
          <v-col cols="12" md="2">
            <text-input
              label="State/Province (required)"
              :rules="requiredRules"
              v-model="input.BillingState"
            >
            </text-input>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              color="black"
              label="Zip/Postal (required)"
              :rules="requiredRules"
              v-model="input.BillingZip"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              color="black"
              label="Country"
              v-model="input.BillingCountry"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="!terminalEnabled && getOrderTotal > 0">
          <v-col cols="12">
            <stripe-element-card
              ref="elementRef"
              @error="handleError"
              :token-data="setTokenData"
              :pk="publishableKey"
              :stripeAccount="paymentAccount"
              @token="process"
            />
          </v-col>
        </v-row>
      </v-container>
      <terms-modal
        v-if="useTermsPopup && !terminalEnabled"
        :terms="eventTerms"
        :agreed="input.termsAgree"
        @on-agree="agree"
      ></terms-modal>
      <v-container v-if="!terminalEnabled">
        <h3 class="mx-2 my-6" v-if="!useTermsPopup">Terms</h3>

        <div class="text-body-1 mx-2" v-if="!useTermsPopup">
          {{ eventTerms }}
        </div>
        <div class="text-body-1 mx-2" v-if="!useTermsPopup">
          By using this site you agree to Tempo's
          <a href="https://www.tempotickets.com/policies" target="_blank"
            >terms</a
          >.
        </div>
        <div class="text-body-1 mx-2" v-if="!useTermsPopup">
          <v-checkbox
            v-model="input.termsAgree"
            color="primary"
            label="I agree to these terms."
            value="true"
          ></v-checkbox>
        </div>
      </v-container>
      <v-row justify="center" class="my-4">
        <v-row justify="center" class="my-4">
          <v-btn
            v-if="!terminalEnabled"
            :loading="loading"
            :disabled="loading"
            color="primary"
            class="ma-2 white--text"
            @click="submit"
            depressed
          >
            Pay {{ (getOrderTotal + getActualDonationAmount) | toCurrency }}
          </v-btn>
          <v-btn
            v-if="terminalEnabled"
            :loading="loading"
            :disabled="loading"
            color="primary"
            class="ma-2 white--text"
            @click="submit"
            depressed
          >
            Capture {{ (getOrderTotal + getActualDonationAmount) | toCurrency }}
          </v-btn>
        </v-row>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CartItems from "./CartItems";
import TextInput from "./includes/TextInput";
import ErrorMessage from "../shared/ErrorMessage";
import EventHeader from "./EventHeader";
import TimeoutMessage from "./TimeoutMessage";
import TermsModal from "./TermsModal";
import Donately from "./includes/Donately";
import { rules } from "@/validation";
import { findDonationCampaign } from "@/repositories/CustomerRepository";
import { makePayment } from "@/repositories/PaymentRepository";
import { getStates } from "@/repositories/AddressRepository";
import { sendConfirmationEmail } from "@/repositories/OrderRepository";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  components: {
    CartItems,
    ErrorMessage,
    EventHeader,
    StripeElementCard,
    TermsModal,
    TimeoutMessage,
    TextInput,
    Donately
  },
  name: "PaymentScreen",
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      confirmParams: {
        return_url: "http://localhost:8080/success" // success url
      },
      elementsOptions: {
        appearance: {} // appearance options
      },
      input: {
        BillingEmail: "",
        BillingFirstName: "",
        BillingLastName: "",
        BillingAddress: "",
        BillingAddress2: "",
        BillingCity: "",
        BillingState: "",
        BillingZip: "",
        BillingPhone: "",
        BillingCountry: "",
        DiscountCode: "",
        termsAgree: false,
        responses: {}
      },
      rules: rules,
      valid: false,
      errorMessage: "",
      discountErrorMessage: "",
      loading: false,
      useTermsPopup: false,
      donationCampaign: null,
      paymentErrorMessage:
        "We are unable to process your credit card as provided; please double-check your information and resubmit.",
      requiredRules: [rules.required],
      emailRules: [rules.required, rules.email],
      terminal: null,
      terminalEnabled: false,
      terminalConnectionState: null,
      terminalPaymentState: null,
      terminalReaders: []
    };
  },
  created() {
    this.$store.dispatch("LOAD_ALL_QUESTIONS");
    if (
      !this.expirySeconds ||
      this.expirySeconds < Math.floor(Date.now() / 1000)
    ) {
      this.$store.commit(
        "STORE_CART_EXPIRY",
        Math.floor(Date.now() / 1000) + 1200
      );
    }
    if (this.event?.FacebookPixelID) {
      this.$analytics.fpq.event("PageLoad", this.$route.path);
    }

    const customerUrl = this.customer.CustomerListingUrl;
    findDonationCampaign(
      customerUrl.substr(customerUrl.lastIndexOf("/") + 1)
    ).then(campaign => {
      this.donationCampaign = campaign;
    });
  },
  computed: {
    setTokenData() {
      return {
        name: `${this.input.BillingFirstName} ${this.input.BillingLastName}`,
        address_line1: this.input.BillingAddress,
        address_line2: this.input.BillingAddress2,
        address_city: this.input.BillingCity,
        address_state: this.input.BillingState,
        address_zip: this.input.BillingZip,
        address_country: this.input.BillingCountry
      };
    },
    ...mapState({
      expirySeconds: state =>
        new Date(state.inventoryResponse?.expires?.date + "Z") / 1000,
      validStates() {
        return getStates();
      },
      event: state => state.event,
      customer: state => state.customer,
      inventoryId: state => state.inventoryResponse?.inventoryId,
      paymentIntentId: state => state.inventoryResponse?.paymentIntentId,
      paymentSecret: state => state.inventoryResponse?.paymentSecret,
      paymentAccount: state => state.inventoryResponse?.paymentAccount,
      order: state => state.order,
      eventTerms: state => state.event?.Terms ?? state.package?.Terms,
      donationAmount: state => state.donationAmount,
      terminalReaderSelected: state => state.terminalReaderSelected
    }),
    ...mapGetters([
      "getOrderTotal",
      "getOrderServiceFee",
      "getActualDonationAmount",
      "getCartLines"
    ]),
    ...{}
  },
  mounted() {
    this.terminalEnabled = false;
    if (this.$store.getters.getOrderTotal > 0) {
      const tag = document.createElement("script");
      tag.setAttribute("src", "https://js.stripe.com/terminal/v1/");
      tag.addEventListener("load", async () => {
        this.terminal = window.StripeTerminal.create({
          onFetchConnectionToken: () => {
            // @TODO: Need to make a backend function to re-generate the payment
            // connection token.
            console.log(
              "onFetchConnectionToken",
              this.$store.state.inventoryResponse?.paymentConnectionToken
            );
            return this.$store.state.inventoryResponse?.paymentConnectionToken;
          },
          onUnexpectedReaderDisconnect: e => {
            this.terminalConnectionState = null;
            this.terminalPaymentState = null;
            console.log("onUnexpectedReaderDisconnect", e);
          },
          onConnectionStatusChange: e => {
            this.terminalConnectionState = e;
            console.log("onConnectionStatusChange", e);
          },
          onPaymentStatusChange: e => {
            this.terminalPaymentState = e;
            console.log("onPaymentStatusChange", e);
          }
        });

        try {
          let discoverResult = [];
          do {
            discoverResult = await this.terminal.discoverReaders();
            await new Promise(resolve => setTimeout(resolve, 1000));
          } while (discoverResult.discoveredReaders.length === 0);

          if (discoverResult.error) {
            console.log("Failed to discover: ", discoverResult.error);
            return;
          } else if (discoverResult.discoveredReaders.length === 0) {
            console.log("No available readers.", discoverResult);
            return;
          }

          this.terminalReaders = discoverResult.discoveredReaders;
          if (this.terminalReaders.length === 1) {
            this.$store.state.terminalReaderSelected =
              discoverResult.discoveredReaders[0];
            this.requiredRules = [];
            console.log("TERMINAL ENABLED");
            this.terminalEnabled = true;
          } else if (this.$store.state.terminalReaderSelected) {
            if (
              !this.terminalReaders.filter(
                r => r.id === this.$store.state.terminalReaderSelected.id
              ).length
            ) {
              this.$store.state.terminalReaderSelected = null;
              this.terminalEnabled = false;
            } else {
              this.requiredRules = [];
              console.log("TERMINAL ENABLED");
              this.terminalEnabled = true;
            }
          }
        } catch (e) {
          this.terminalEnabled = false;
          return;
        }
      });
      document.head.appendChild(tag);
    }
    this.elementsOptions.clientSecret = this.$store.state.inventoryResponse?.paymentSecret;
  },
  methods: {
    handleError(e) {
      this.errorMessage = e;
    },
    setReaderSelected(id) {
      this.$store.state.terminalReaderSelected = this.terminalReaders.find(
        r => r.id === id
      );
    },
    async process(token) {
      this.loading = true;
      this.errorMessage = "";
      if (!this.terminalEnabled) {
        if (!this.$refs.form.validate()) {
          this.errorMessage =
            "Please correct errors and resubmit your payment.";
          this.loading = false;
          return;
        }
        if (!this.input.termsAgree) {
          this.errorMessage =
            "Please agree to the terms and conditions to complete your purchase.";
          this.loading = false;
          return;
        }
        if (!token && this.$store.getters.getOrderTotal !== 0) {
          this.errorMessage = "Please enter a valid credit card.";
          this.loading = false;
          return;
        }
      }

      if (this.terminalEnabled) {
        try {
          const connectResult = await this.terminal.connectReader(
            this.terminalReaderSelected
          );

          if (connectResult.error) {
            console.log("Failed to connect: ", connectResult.error);
            this.errorMessage =
              "Failed to connect to card reader: " + connectResult.error;
            return;
          }
          console.log("Connected to reader: ", connectResult.reader.label);
        } catch (e) {
          console.log("Failed to connect reader", e);
          this.errorMessage = "Failed to connect to card reader: " + e.message;
          return;
        }

        let paymentIntent;
        try {
          const paymentRequest = await this.terminal.collectPaymentMethod(
            this.$store.state.inventoryResponse?.paymentSecret
          );
          if (paymentRequest.error) {
            this.errorMessage =
              "Failed to collect payment: " + paymentRequest.error;
            console.log("collectPaymentMethod error", paymentRequest.error);
            return;
          }

          paymentIntent = paymentRequest.paymentIntent;

          console.log("payment intent", paymentIntent);
        } catch (e) {
          this.errorMessage = "Failed to collect payment: " + e.message;
          console.log("failed to collect payment method");
          return;
        }

        try {
          const processPayment = await this.terminal.processPayment(
            paymentIntent
          );
          console.log("processPayment", processPayment);

          this.errorMessage = "";
        } catch (e) {
          this.errorMessage = "Failed to process payment: " + e.message;
          console.log("error processing payment", e);
          return;
        }
      }

      try {
        const response = await makePayment({
          inventoryId: this.inventoryId,
          capture: this.terminalEnabled,
          donation: this.getActualDonationAmount,
          stripePaymentIntentId: this.paymentIntentId,
          stripePaymentSecret: this.paymentSecret,
          stripeToken: token,
          ...this.input,
          tickets: this.$store.getters.getCartLines
        });

        if (response.data.paid) {
          if (response.data.billing_details.email) {
            await sendConfirmationEmail(response.data.id);
          }
          await this.$store.commit("UPDATE_INVENTORY_RESPONSE", {
            orderId: response.data.id,
            ticketLink: response.data.ticket_link,
            email: response.data.billing_details.email,
            complete: true
          });
          await this.$store.commit("COMPLETED_ORDER");
          await this.$store.commit("REMOVE_EVENT");
          this.$router.push("paymentComplete");
          window.location.reload();
        } else {
          console.log("API processing payment ISSUE: ", response, {
            inventoryId: this.inventoryId,
            capture: this.terminalEnabled,
            donation: this.getActualDonationAmount,
            stripePaymentIntentId: this.paymentIntentId,
            stripePaymentSecret: this.paymentSecret,
            stripeToken: token,
            ...this.input,
            tickets: this.$store.getters.getCartLines
          });
          this.errorMessage = this.paymentErrorMessage;
        }
      } catch (error) {
        console.log("API processing payment ERROR: ", error);
        this.errorMessage =
          "We are experiencing payment issues, please try again later.";
      } finally {
        this.loading = false;
      }
    },
    agree() {
      this.input.termsAgree = true;
    },
    setExpired() {
      this.$store.commit("REMOVE_EVENT");
      this.$router.push("expired");
      window.location.reload();
    },
    cancelOrder() {
      this.$store.commit("REMOVE_EVENT");
      this.$router.push(this.customer.CustomerListingUrl);
    },

    submit() {
      console.log("submit", this.$store.getters.getOrderTotal);
      if (this.terminalEnabled || this.$store.getters.getOrderTotal === 0) {
        this.process();
      } else {
        this.$refs.elementRef.submit();
      }
    },
    async applyDiscount() {
      this.loading = true;
      this.discountErrorMessage = "";

      const coupon = this.$store.state.event.Coupons.find(
        coupon =>
          coupon.CouponCode.toLowerCase() ===
          this.input.DiscountCode.toLowerCase()
      );

      //check the date of the discount is still valid
      if (
        !isNaN(new Date(coupon?.LastDay).getTime()) &&
        new Date(coupon?.LastDay).getTime() > 0 &&
        new Date(coupon?.LastDay).getTime() < new Date().getTime()
      ) {
        this.discountErrorMessage = "Discount Code has Expired";
        return;
      }

      // check the quantity to make sure there is enough uses left if set
      if (coupon?.MaximumUses > 0) {
        const quanity = this.$store.state.inventoryResponse?.tickets?.reduce(
          (acc, t) => {
            if (
              coupon?.TicketTypes?.length > 0 &&
              !coupon?.TicketTypes?.includes(t.id)
            ) {
              return acc;
            }

            return acc + parseInt(t.quantity);
          },
          0
        );

        if (coupon?.MaximumUses < coupon?.DiscountsUsed + quanity) {
          this.discountErrorMessage =
            "Discount Code cannot be applied to selected quatity";
          return;
        }
      }

      //check to make sure the discount code is valid for the ticket types if applicable
      if (coupon?.TicketTypes?.length > 0) {
        const tickets =
          this.$store.state.inventoryResponse?.tickets?.filter(t =>
            coupon?.TicketTypes?.includes(t.id)
          ) || [];
        if (tickets.length === 0) {
          this.discountErrorMessage =
            "Discount Code not valid for current selection";
          return;
        }
      }

      await this.$store.commit("REMOVE_APPLIED_COUPON");
      if (coupon !== undefined) {
        await this.$store.commit("STORE_APPLIED_COUPON", coupon);
      } else if (this.input.DiscountCode.length > 0) {
        this.discountErrorMessage = "Discount Code Invalid";
      }
      this.loading = false;
    }
  }
};
</script>
