<template>
  <v-container>
    <v-row class="text-center ma-5">
      <div class="text-h4">
        Hi {{ user ? user.firstName : "Unknown Visitor" }}!
      </div>
    </v-row>
    <v-row class="text-center mx-5">
      <div>
        The links below demonstrate the functionality of the new and improved
        Tempo Tickets ordering.
      </div>
    </v-row>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="login"
              >Login</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="createAccount"
              >Create Account</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-btn depressed outlined color="primary" to="listing/y0nljh"
              >Pride 2024</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="listing/N9nAXu"
              >Lakefront Music Fest</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="listing/minZL7"
              >Latvian Song and Dance</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="listing/jtnWFy"
              >St. Croix Casinos</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="event/UltnHl"
              >Gobble Gallop</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            ><v-btn depressed outlined color="primary" to="ticket"
              >Sample Ticket</v-btn
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>
