<template>
  <v-app>
    <nav-bar></nav-bar>
    <v-main>
      <v-divider class="error"></v-divider>
      <v-img
        src="./assets/tempo-background.png"
        max-height="100%"
        min-height="100%"
      >
        <router-view></router-view>
      </v-img>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/shared/NavBar";
export default {
  components: {
    NavBar
  },
  name: "App",
  computed: {
    cartExpires: () => this.$store.state.cartExpires
  },
  mounted() {
    this.clearCartInterval = setInterval(() => {
      if (this?.$store?.state?.cartExpires) {
        const cartExpires = this?.$store?.state?.cartExpires;
        if (cartExpires && cartExpires < Date.now() / 1000) {
          this.$store.dispatch("CLEAR_CART");
        }
      }
    }, 5000);
  },
  destroyed: () => {
    clearInterval(this.clearCartInterval);
  }
};
</script>
