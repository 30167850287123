import Client from "./Client";

export async function holdInventory(
  eventId,
  eventDateId,
  tickets,
  inventory_id
) {
  const httpClient = new Client();
  try {
    const response = await httpClient.post(`inventory/hold/event/${eventId}`, {
      body: {
        event_date_id: eventDateId,
        ticket_types: tickets,
        inventory_id
      }
    });
    return response.data;
  } catch (err) {
    // this endpoint will send a detailed message in the response so let's send that back to calling method
    return {
      error: true,
      data: err.response.data
    };
  }
}

export async function holdPackageInventory(packageId, quantity, inventory_id) {
  const httpClient = new Client();
  try {
    const response = await httpClient.post(
      `inventory/hold/package/${packageId}`,
      {
        body: {
          quantity,
          inventory_id
        }
      }
    );
    return response.data;
  } catch (err) {
    // this endpoint will send a detailed message in the response so let's send that back to calling method
    return {
      error: true,
      data: err.response.data
    };
  }
}

export async function removeTicketHold(inventoryId, TicketTypeID) {
  const httpClient = new Client();
  try {
    const response = await httpClient.delete(`inventory/${inventoryId}`, {
      data: {
        TicketTypeID
      }
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}

export async function removePackageHold(inventoryId, PackageID) {
  const httpClient = new Client();
  try {
    const response = await httpClient.delete(`inventory/${inventoryId}`, {
      data: {
        PackageID
      }
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
}
