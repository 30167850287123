<template>
  <div>
    <div class="text-center mx-5" v-if="!isEventLoaded">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-container class="white pa-4" v-if="isEventLoaded">
      <v-row class="my-2 mx-8">
        <v-col cols="12" md="12">
          <event-header
            :event="event"
            :show-return-button="true"
            :show-event-name="true"
          ></event-header>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row class="my-2 mx-8" v-if="event.EventID !== 9376">
        <v-col cols="12" md="12">
          <div class="text-h5" v-if="event.AltDateLabel">
            {{ event.AltDateLabel }}
          </div>
          <div
            class="text-h5"
            v-if="!event.AltDateLabel && event.firstDate === event.lastDate"
          >
            {{ event.lastDate }}
          </div>
          <div
            class="text-h5"
            v-if="!event.AltDateLabel && event.firstDate !== event.lastDate"
          >
            {{ event.firstDate }} - {{ event.lastDate }}
          </div>
          <div>at {{ event.Venue.Name }}</div>
        </v-col>

        <v-col cols="auto">
          <!-- AddToAny BEGIN -->
          <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
            <span class="font-weight-bold float-left mr-1">Share</span>
            <a class="a2a_button_email"></a>
            <a class="a2a_button_facebook"></a>
            <a class="a2a_button_twitter"></a>
            <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
          </div>
          <!-- AddToAny END -->
          <!-- if ticket data, waiting on api change -->
        </v-col>

        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row class="my-4 mx-8" v-if="event.Description">
        <v-col cols="12" md="12">
          <h3>About the Event</h3>
          <div v-html="event.Description"></div>
        </v-col>
      </v-row>
      <v-row class="my-4 mx-8" v-if="event.Note">
        <v-col cols="12" md="12">
          <div v-html="event.Note"></div>
        </v-col>
      </v-row>
      <!-- If social media info, waiting on api change  -->
      <v-row
        align="center"
        v-if="
          event.SocialMedia.Instagram ||
            event.SocialMedia.Twitter ||
            event.SocialMedia.Facebook.Path
        "
        class="my-4 mx-8"
      >
        <v-col cols="auto">
          <div
            v-if="event.SocialMedia.Facebook.Path"
            class="fb-like mr-4"
            :data-href="`facebook.com/${event.SocialMedia.Facebook.Path}`"
            data-width=""
            data-layout="button"
            data-action="like"
            data-size="large"
            data-share="false"
          ></div>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="event.SocialMedia.Instagram"
            class="mr-4"
            target="_blank"
            :href="`https://www.instagram.com/${event.SocialMedia.Instagram}`"
          >
            <v-icon class="mr-1">mdi-instagram </v-icon>
            View on Instagram
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="event.SocialMedia.Twitter"
            target="_blank"
            :href="`https://www.twitter.com/${event.SocialMedia.Twitter}`"
          >
            <v-icon class="mr-1">mdi-twitter </v-icon>
            Follow on Twitter
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="py-0 px-0" v-if="event.Dates.length > 1">
        <v-row class="my-0 mx-8">
          <v-col class="py-1" cols="12" md="12">
            <h3>Choose date:</h3>
          </v-col>
        </v-row>
        <v-row v-if="soldOut.length" justify="center">
          <div>
            <v-icon color="red" class="mr-1">mdi-checkbox-blank-circle</v-icon>=
            Sold Out
          </div>
        </v-row>
        <!-- remove calendar option for time-being -->
        <!-- <v-row
          class="my-0 mx-8"
          align="center"
          v-if="this.event.IsCalendarView"
        >
          <v-col class="py-1" cols="12" md="12">
            <v-date-picker
              v-model="date"
              no-title
              width="320"
              @input="chooseDate"
              :allowed-dates="isValidDate"
              :events="soldOut"
              event-color="red"
              color="primary"
              class="mt-4"
            ></v-date-picker>
          </v-col>
        </v-row> -->
        <v-row class="my-4 mx-8">
          <v-sheet
            class="date-box px-6 py-3 ma-3"
            style="position: relative"
            :class="{
              past: isPast(eDate.Date),
              chosen: chosenDate && chosenDate.DateID === eDate.DateID
            }"
            v-for="eDate of eventDates"
            :key="eDate.DateID"
            v-on:click="
              !isPast(eDate.Date) && !eDate.SoldOut && chooseDate(eDate)
            "
          >
            <div class="text-center text-subtitle-2">
              {{ getDate(eDate.Date).month }}
            </div>
            <div class="text-center font-weight-bold text-h6">
              {{ getDate(eDate.Date).date }}
            </div>
            <!-- Needs API change to see if tickets are sold out -->
            <v-overlay
              color="#fff"
              opacity="0.2"
              class="sold-out-overlay"
              v-if="eDate.SoldOut"
              absolute
            >
              <p class="sold-out font-weight-bold red--text">Sold Out</p>
            </v-overlay>
          </v-sheet>
        </v-row>
      </v-container>
      <v-row class="mx-8">
        <v-col cols="12" md="12">
          <h3>Choose quantities</h3>
        </v-col>
      </v-row>
      <v-row v-if="event.TotalAvailable == 0" class="mx-8 my-4">
        <v-col cols="12" md="12">
          <h3 class="red--text">
            Sorry, this event is completely sold out
          </h3>
        </v-col>
      </v-row>
      <div v-if="event.TotalAvailable > 0">
        <v-container
          v-for="(ticketType, ttIndex) of event.TicketTypes"
          :key="ticketType.TypeID"
        >
          <v-row class="my-2 mx-8">
            <v-col cols="12" md="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="my-2 mx-8">
            <v-col cols="12" md="8">
              <div>
                {{ ticketType.Label }}:
                {{ ticketType.Price | toCurrency }}
                + {{ ticketType.ServiceFee | toCurrency }} Service Fee
              </div>
              <div
                v-if="ticketType.EarlyPurchaseIntervalLastDay"
                class="price-subtitle"
              >
                {{ ticketType.FullPrice | toCurrency }} starting
                {{ ticketType.EarlyPurchaseIntervalLastDay }}
              </div>
              <div>{{ ticketType.Note }}</div>
            </v-col>
            <v-col cols="12" md="4" class="text-right primary--text">
              <quantity-selector
                :index="ttIndex"
                :quantity="ticketQuantities[ttIndex]"
                @onQuantityChange="setQuantity"
                name="Ticket"
                :price="ticketType.Price + ticketType.ServiceFee"
                :max="ticketType.MaxQuantity"
                :min="ticketType.MinQuantity"
              ></quantity-selector>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-row class="mx-2 my-2" justify="center">
        <v-btn
          color="primary"
          depressed
          @click="requestTickets"
          :disabled="getTotalQuantitySelected <= 0"
          >Add to cart</v-btn
        >
      </v-row>
      <v-row
        class="mx-8 my-4"
        v-if="event.EventID !== 9376 && event.EventID < 1"
      >
        <v-col cols="12" md="12">
          <h3>Location</h3>
          <div>{{ event.Venue.Name }}</div>
          <div>
            <span v-if="event.Venue.Address">{{ event.Venue.Address }}, </span
            >{{ event.Venue.City }},
            {{ event.Venue.State }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-8 mx-4" v-if="event.Venue.ParkingInfo">
        <v-col cols="12" md="12">
          <h3>Parking</h3>
          <div>{{ event.Venue.ParkingInfo }}</div>
        </v-col>
      </v-row>
      <v-row class="mx-8 mx-4" v-if="event.Venue.AccessiblityInfo">
        <v-col cols="12" md="12">
          <h3>Accessibility</h3>
          <div>{{ event.Venue.AccessiblityInfo }}</div>
        </v-col>
      </v-row>
      <v-row class="mx-8 mx-4" v-if="event.EventID !== 9376">
        <v-col cols="12" md="12" justify="center">
          <iframe
            width="600"
            height="450"
            style="border: 0"
            loading="lazy"
            allowfullscreen
            :src="mapSource"
          ></iframe>
        </v-col>
      </v-row>
      <event-not-ready
        :display="event.notOnSale"
        :onSale="event.onSaleFormatted"
      ></event-not-ready>
      <event-over
        :display="event.eventOver"
        :customerName="event.CustomerCategoryName"
        :listingUrl="event.CustomerListingUrl"
      ></event-over>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import EventHeader from "./EventHeader";
import EventNotReady from "@/components/shared/EventNotReady";
import EventOver from "@/components/shared/EventOver";
import QuantitySelector from "@/components/shared/QuantitySelector";
import TagManager from "@/services/TagManager";
// eslint-disable-next-line no-unused-vars
import { format, parseISO, formatIso, isAfter } from "date-fns";

export default {
  components: {
    EventHeader,
    EventNotReady,
    EventOver,
    QuantitySelector
  },
  data() {
    return {
      date: ""
    };
  },
  computed: {
    ...mapGetters(["isEvent", "getTotalQuantitySelected"]),
    ...mapState({
      event: state => state.event,
      chosenDate: state => state.chosenEventDate,
      inventoryResponse: state => state.inventoryResponse,
      ticketQuantities: state => state.ticketQuantities,
      mapSource() {
        const placeName = encodeURIComponent(
          this.event.Venue.Address +
            ", " +
            this.event.Venue.City +
            ", " +
            this.event.Venue.State
        );
        return `https://www.google.com/maps/embed/v1/place?q=${placeName}&key=AIzaSyDRoXy_nt-R3cTE3vilBnGHca3oPeLFP6U`;
      },
      isEventLoaded() {
        // this is specific to this page.  It ensures the page doesn't render with an older event while the current event
        //   is getting fetched.
        return (
          this.event?.EventPath === this.$route.params.code ||
          this.event?.EventID == this.$route.params.code ||
          this.$route.name === "customEvent"
        );
      },
      eventDates() {
        // filtering to just future dates
        if (this.event && this.event.Dates) {
          const dates = [...this.event?.Dates];
          dates.sort((a, b) => new Date(b.Date) - new Date(a.Date));
          let idx = null;
          const sortedDates = dates.filter((date, index) => {
            if (new Date(date.Date) > new Date()) {
              idx = index;
              return date;
            } else if (index <= idx) {
              return date;
            }
          });
          return sortedDates.reverse();
        }

        return [];
      },
      firstEvent() {
        const dates = this.eventDates?.map(x =>
          format(parseISO(x.Date), "yyyy-MM-dd")
        );

        if (dates) {
          return dates[0];
        }
      },
      calendarDates() {
        const futures = this.eventDates;
        return futures.map(x => format(parseISO(x.Date), "yyyy-MM-dd"));
      },
      soldOut() {
        const futures = this.eventDates;
        return futures
          .filter(x => x.SoldOut)
          .map(x => format(parseISO(x.Date), "yyyy-MM-dd"));
      }
    })
  },
  methods: {
    setQuantity(quantity, index) {
      this.$store.commit("UPDATE_TICKET_QUANTITIES", { index, quantity });
    },
    isValidDate(val) {
      const fil = this.calendarDates.filter(x => !x.SoldOut);
      return fil.includes(val);
    },
    requestTickets() {
      this.$router.push("/inventoryRequest");
    },
    pushGtm() {
      if (this.event.GoogleTagManagerContainerID) {
        const options = {
          gtmId: this.event.GoogleTagManagerContainerID
        };
        const tagManager = new TagManager(options);
        tagManager.initialize(options);
        // tagManager.push({event:"Loaded"});
      }
    },
    getDate(eDate) {
      const month = format(new Date(eDate), "MMM");
      const date = format(new Date(eDate), "d");
      return { month, date };
    },
    isPast(date) {
      return isAfter(new Date(), new Date(date));
    },
    chooseDate(eDate) {
      this.$store.commit("CHOOSE_EVENT_DATE", eDate);
    }
  },
  created() {
    this.date = this.firstEvent;
    this.$store
      .dispatch("LOAD_EVENT", this.$route.params.code)
      .then(() =>
        this.$store.dispatch("LOAD_TICKET_QUANTITIES", { isEvent: true })
      );
    // this.pushGtm();
    if (this.isEvent) {
      this.pushGtm();
    }

    this.$watch("isEvent", function(newValue) {
      if (newValue) {
        this.pushGtm();
      }
    });

    // if (e.FacebookPixelID) {
    //   this.$analytics.fbq.init(e.FacebookPixelID);
    //   this.$analytics.fpq.event("PageLoad", this.$route.path);
    // }
    if (window.a2a) {
      setTimeout(() => window.a2a.init_all(), 100);
    }
  }
};
</script>

<style scoped>
.sold-out {
  font-size: 20px;
  opacity: 0.8;
}
.sold-out-overlay {
  text-align: center;
}
.price-subtitle {
  color: #919699;
  font-size: 13px;
}
.date-box {
  color: #afb5bb;
  border: 1.5px solid #afb5bb;
  border-radius: 14px;
  cursor: pointer;
}
.date-box.past {
  background: #f2f2f2;
  border: 3px solid #f2f2f2;
  cursor: unset;
}
.date-box.chosen {
  color: #212224;
  border: 3px solid #f2612d;
}
</style>
