<template>
  <v-container class="white pa-4">
    <v-alert type="success">{{ statusMessage }}</v-alert>
    <v-row
      justify="center"
      class="mt-8 mb-4"
      v-if="customer.CustomerListingUrl == '/listing/minZL7'"
    >
      <v-col cols="12" md="6">
        Ticket revenues will pay for about 50% of Festival expenses. Please
        consider a tax deductible donation to help us with our other expenses.
        <a
          href="https://pages.donately.com/latviansonganddancefestivalinc/campaign/help-fund-songfest"
          >DONATE HERE</a
        >
      </v-col>
    </v-row>
    <v-row justify="center" class="my-2" v-if="!checkUser">
      <v-btn
        :href="completedOrder.ticketLink"
        depressed
        outlined
        color="error"
        target="_blank"
        >View Ticket(s)</v-btn
      >
    </v-row>
    <!-- // !! disabled  -->
    <!-- <v-row justify="center" class="my-4" v-if="!checkUser">
      <v-sheet flat rounded color="#FDE8E0">
        <v-row>
          <v-col cols="12" md="4" class="px-4 pb-6">
            <v-img alt="two tickets" src="../../assets/tickets.png"></v-img>
          </v-col>
          <v-col cols="12" md="8">
            <div class="text-h4 mb-2 mt-6 px-2">
              Viewing your tickets, made easy.
            </div>
            <div class="text-caption px-2">
              Quickly view all your tickets by creating an account.
            </div>
            <div class="my-12">
              <v-btn to="createAccount" depressed color="error"
                >Create Account</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-row> -->
    <v-row justify="center" class="my-4" v-if="checkUser">
      <v-btn depressed color="error" class="mx-6" to="dashboard"
        >Go To Account</v-btn
      >
      <v-btn :href="completedOrder.ticketLink" depressed outlined color="error"
        >View Tickets</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  created() {
    // this.$store.commit("STORE_USER", { id: 1 });
  },
  computed: {
    ...mapState({
      statusMessage() {
        if (this.completedOrder.email) {
          return `Purchase Successful! Confirmation email was sent to ${this.completedOrder.email}.`;
        } else {
          return `Purchase Successful!`;
        }
      },
      checkUser: state => state.user,
      completedOrder: state => state.completedOrder,
      customer: state => state.customer
    }),
    ...mapGetters(["getCompletedOrderTotal", "getCompletedTickets"])
  }
};
</script>
