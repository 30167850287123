var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4",attrs:{"color":_vm.pkg.LandingPageColor,"width":"80%"}},[(_vm.pkg.BadgeText)?_c('v-row',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#BEE3FF","label":""}},[_c('v-icon',{attrs:{"left":"","color":"#0B5289"}},[_vm._v(" mdi-tag-outline ")]),_c('span',{staticClass:"tempo-badge"},[_vm._v(_vm._s(_vm.pkg.BadgeText))])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('h2',{class:{ 'white--text': _vm.pkg.LandingPageTextColor == 'light' }},[_vm._v(" "+_vm._s(_vm.pkg.Title)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{class:{
          'white--text': _vm.pkg.LandingPageTextColor == 'light'
        }},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.pkg.Price))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-divider',{staticClass:"my-2"}),_c('v-row',[(_vm.pkg.Producer)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.pkg.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.pkg.LandingPageTextColor == 'light'
            }},[_vm._v(" Presented By ")]),_c('div',{class:{
              'white--text': _vm.pkg.LandingPageTextColor == 'light',
              'my-2': true
            }},[_vm._v(" "+_vm._s(_vm.pkg.Producer)+" ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.pkg.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.pkg.LandingPageTextColor == 'light'
            }},[_vm._v(" Location ")]),_c('div',{class:{
              'white--text': _vm.pkg.LandingPageTextColor == 'light',
              'my-2': true
            }},_vm._l((_vm.pkg.VenueNames),function(venue,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(index === 0 || venue !== _vm.pkg.VenueNames[index - 1] ? venue : null)+" ")])}),0)]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{class:{
              'tempo-subtitle': _vm.pkg.LandingPageTextColor != 'light',
              'tempo-subtitle-light': _vm.pkg.LandingPageTextColor == 'light'
            }},[_vm._v(" Date(s) ")]),_c('div',{class:{
              'white--text': _vm.pkg.LandingPageTextColor == 'light',
              'my-2': true
            }},_vm._l((_vm.pkg.Dates),function(d){return _c('div',{key:d.DateID},[_vm._v(" "+_vm._s(_vm._f("toDateWithTime")(d.Date))+" ")])}),0)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","to":_vm.pkg.BookingUrl}},[_vm._v("Select")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }