<template>
  <v-alert color="accent" v-if="message" class="mb-12">
    <template v-slot:prepend>
      <v-icon color="primary">mdi-alert</v-icon>
    </template>
    <span style="color: #f22632" class="mx-4">{{ message }}</span>
  </v-alert>
</template>

<script>
export default {
  props: ["message"]
};
</script>
