import Client from "./Client";

export async function findCustomer(codeOrCustomerId) {
  const httpClient = new Client();
  const response = await httpClient.get(`customer/${codeOrCustomerId}`);
  return response.data;
}

export async function findDonationCampaign(codeOrCustomerId) {
  const httpClient = new Client();
  const response = await httpClient.get(
    `donation/customer/${codeOrCustomerId}`
  );
  return response.data;
}
