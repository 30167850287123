<template>
  <div>
    <div class="float-right pr-2 pt-2">{{ index }} of {{ total }}</div>
    <div class="text-center">
      <h2>Order #{{ ticket.orderId }}</h2>
    </div>
    <div class="float-right">
      <v-btn icon @click="nextTicket" :disabled="index >= total">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <div class="float-left">
      <v-btn icon @click="previousTicket" :disabled="index <= 0">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </div>
    <div class="text-center">
      <qrcode-vue :value="ticket.barcode" size="300" level="H" />
    </div>

    <div class="text-center">{{ readableBarcode }}</div>
    <div class="pa-2">
      <h3>{{ ticket.eventName }}</h3>
      <p v-if="ticket.eventName != 'Festival Guidebook'">
        {{ ticket.eventDate }}
      </p>
      <p v-if="ticket.specialNotes" v-html="ticket.specialNotes"></p>
      <p class="py-2">{{ ticket.ticketType }}</p>
      <v-list dense>
        <v-list-item v-if="ticket.eventName != 'Festival Guidebook'">
          <v-list-item-icon>
            <v-icon>mdi-map-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="ticket.venueName"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="ticket.customerEmail"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-phone-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="ticket.customerPhone"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="ticket.customerWebsite"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "mobile-ticket",
  components: {
    QrcodeVue
  },
  props: ["ticket", "onNextTicket", "onPreviousTicket", "index", "total"],
  computed: {
    readableBarcode() {
      const code = this.ticket.barcode;
      if (code?.length < 6) {
        // usually bar codes are 8 digits but if that changes we don't want substring errors.
        return code;
      }
      return (
        code.substring(0, 3) +
        " " +
        code.substring(3, 6) +
        " " +
        code.substring(6)
      );
    }
  },
  methods: {
    nextTicket() {
      this.$emit("onNextTicket");
    },
    previousTicket() {
      this.$emit("onPreviousTicket");
    }
  }
};
</script>
