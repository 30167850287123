/**
 * Global Tag Manager instance
 */
class TagManager {
  options = {
    gtmId: undefined,
    queryParams: {},
    dataLayerName: "dataLayer",
    dataLayer: undefined,
    scriptUrl: "//www.googletagmanager.com/gtm.js"
  };

  // Should this be removed since we already have the
  constructor(options) {
    this.options = { ...this.options, ...options };

    if (!this.options.gtmId) {
      throw "No GTM id provided";
    }
  }

  /**
   * Returns the URL and query params to get the tag manager script
   */
  getScriptUrl() {
    const id = this.options.gtmId;
    const url = this.options.scriptUrl;
    const queryParams = {
      id,
      l: this.options.dataLayerName,
      ...this.options.queryParams
    };

    const queryString = Object.keys(queryParams)
      .filter(
        key => queryParams[key] !== null && queryParams[key] !== undefined
      )
      .map(
        key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join("&");

    return `${url}?${queryString}`;
  }

  /**
   * Returns the script to initialize the data layer
   */
  getDataLayerScriptContent() {
    const dataLayerName = this.options.dataLayerName;
    const initialValues = this.options.dataLayer;
    const scriptArray = [];

    // Initialize data layer
    scriptArray.push(
      `window['${dataLayerName}'] = window['${dataLayerName}'] || [];`
    );

    if (initialValues) {
      scriptArray.push(
        `window['${dataLayerName}'].push(${JSON.stringify(initialValues)});`
      );
    }

    scriptArray.push(
      `window['${dataLayerName}'].push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });`
    );

    return scriptArray.join("\n");
  }

  /**
   * Push an event to the data layer
   * @param event event object
   */
  push(event) {
    if (!(event instanceof Object) || event instanceof Array)
      throw "Pushed event is not an object.";

    const dataLayer = this.options.dataLayerName;
    window[dataLayer].push(event);
  }

  initialize(options) {
    if (window.vgtmInstalled) return;
    try {
      var tagManager = new TagManager(options);
      this.injectScriptTagIntoHead(
        this.getScriptTagWithContent(this.getDataLayerScriptContent())
      );
      this.injectScriptTagIntoHead(
        this.getScriptTagWithSrc(this.getScriptUrl(), true)
      );
      var variableName = options.TagManagerVariableName || "TagManager";
      window[variableName] = tagManager;
      window.vgtmInstalled = true;
    } catch (error) {
      console.log(error);
    }
  }

  injectScriptTagIntoHead(scriptTag) {
    if (!scriptTag) throw "No script tag passed.";
    window.document.head.appendChild(scriptTag);
  }

  getScriptTagWithContent(script) {
    if (!script) throw "No script content passed.";
    var scriptTag = this.createScriptTag();
    scriptTag.innerHTML = script;
    return scriptTag;
  }

  createScriptTag() {
    var scriptTag = document.createElement("script");
    return scriptTag;
  }

  getScriptTagWithSrc(src, isAsync) {
    isAsync = !!false;
    if (!src) throw "No src passed.";
    var scriptTag = this.createScriptTag();
    scriptTag.src = src;
    scriptTag.async = isAsync;
    return scriptTag;
  }
}

export default TagManager;
