<template>
  <v-container>
    <v-row v-if="nothingOnSale" class="text-center">
      <v-sheet class="pa-4" color="white" min-width="80%"
        >No events for sale right now.</v-sheet
      >
    </v-row>
    <v-row v-if="CustomerBannerImage" class="py-4">
      <v-img max-width="80%" :src="CustomerBannerImage"></v-img>
    </v-row>
    <v-row>
      <!-- AddToAny BEGIN -->
      <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
        <span class="font-weight-bold float-left mr-1">Share</span>
        <a class="a2a_button_email"></a>
        <a class="a2a_button_facebook"></a>
        <a class="a2a_button_twitter"></a>
        <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
      </div>
    </v-row>
    <div
      class="text-center my-10"
      v-if="customerLoading || packagesLoading || eventsLoading"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row
      v-for="eOrP in sortedEventsAndPackages"
      :key="eOrP.isEvent ? 'e' + eOrP.EventID : 'p' + eOrP.PackageID"
      class="my-4"
    >
      <package-view :pkg="eOrP" v-if="!eOrP.isEvent"></package-view>
      <event-view :event="eOrP" v-if="eOrP.isEvent"></event-view>
    </v-row>
  </v-container>
</template>

<script>
import compareAsc from "date-fns/compareAsc";
import parse from "date-fns/parse";
import { findCustomer } from "@/repositories/CustomerRepository";
import { findEventsByCustomer } from "@/repositories/EventRepository";
import { findPackagesByCustomer } from "@/repositories/PackageRepository";
import PackageView from "./PackageView.vue";
import EventView from "./EventView.vue";

export default {
  props: ["code"],
  components: {
    PackageView,
    EventView
  },
  data() {
    return {
      events: [],
      packages: [],
      customer: null,
      customerLoading: false,
      eventsLoading: false,
      packagesLoading: false
    };
  },
  created() {
    this.customerLoading = true;
    this.eventsLoading = true;
    this.packagesLoading = true;
    findEventsByCustomer(this.code).then(events => {
      this.events = events;
      this.eventsLoading = false;
    });
    findPackagesByCustomer(this.code).then(packages => {
      this.packages = packages.filter(p => p.Price !== 0);
      this.packagesLoading = false;
    });
    findCustomer(this.code).then(customer => {
      document.title = `Tickets for ${customer.Name}`;
      this.customer = customer;
      this.customerLoading = false;
    });
    if (window.a2a) {
      setTimeout(() => window.a2a.init_all(), 100);
    }
  },
  computed: {
    nothingOnSale() {
      return (
        !this.packagesLoading &&
        !this.customerLoading &&
        !this.eventsLoading && // we don't want to show off sale while data loads
        (!this.packages || this.packages.length === 0) &&
        (!this.events || this.events.length === 0)
      );
    },
    CustomerBannerImage() {
      return this.customer?.BannerImage
        ? `https://www.tempotickets.com/tempotickets/file_store/${this.customer.BannerImage}`
        : null;
    },
    sortedEventsAndPackages() {
      if (this.packages?.length <= 0) {
        return this.events;
      }
      let combined = this.events.concat(this.packages);

      combined.sort((a, b) => {
        if ("PackageID" in a && a.PackageID === 249) {
          // Adding exception for listing/y0nljh
          return -1;
        } else if ("PackageID" in b && b.PackageID === 249) {
          // Adding exception for listing/y0nljh
          return 1;
        }
        if (a.EventID === 9376) {
          return 1; // Latvian Festvial Guidebook should be last.  TODO: let's make this property in the event.
        }
        if (b.EventID === 9376) {
          return -1;
        }
        return compareAsc(
          parse(a.FirstDate, "yyyy-MM-dd HH:mm", new Date()),
          parse(b.FirstDate, "yyyy-MM-dd HH:mm", new Date())
        );
      });
      return combined;
    }
  }
};
</script>
