import Client from "./Client";
import {
  formatPackagesInList,
  formatSinglePackage
} from "../services/PackageFormatter";

export async function findPackagesByCustomer(codeOrCustomerId) {
  const httpClient = new Client();
  const response = await httpClient.get(
    `packages/by-customer/${codeOrCustomerId}`
  );
  return formatPackagesInList(response.data);
}

export async function findPackage(codeOrPackageId) {
  const httpClient = new Client();
  const response = await httpClient.get(`packages/${codeOrPackageId}`);
  return formatSinglePackage(response.data);
}
