import { min, max, format, isAfter, parse } from "date-fns";

/**
 *
 * @param {*} events - list of events returned from EventRepository
 */
export const formatEventsInList = events => {
  return events.map(e => {
    return {
      ...e,
      minDate: format(
        parse(e.FirstDate, "yyyy-MM-dd HH:mm", new Date()),
        "EEE MMM d @ h:mma"
      ),
      maxDate: max(
        e.Dates.map(d => parse(d.Date, "yyyy-MM-dd HH:mm", new Date()))
      ),
      isEvent: true
    };
  });
};

export const formatSingleEvent = (event, customizations) => {
  let minDate = null;
  let maxDate = null;
  const dates = event.Dates?.map(d => {
    try {
      return parse(d.Date, "yyyy-MM-dd HH:mm", new Date());
    } catch (error) {
      console.log("parse", error);
    }
  });

  try {
    minDate = min(dates);
    maxDate = max(dates);
  } catch (err) {
    console.log("DATE ERROR", err);
  }

  const { TicketOrder, ...remainingCustomizations } = customizations;
  const ticketTypes = TicketOrder
    ? TicketOrder.map(typeID => {
        const foundTypes = event.TicketTypes.filter(
          tt => tt?.TypeID === typeID
        );
        return foundTypes ? foundTypes[0] : {};
      })
    : event.TicketTypes;
  return {
    ...event,
    TicketTypes: ticketTypes.map(tt => {
      return {
        ...tt,
        Price: parseInt(tt.Price),
        ServiceFee: parseInt(tt.ServiceFee)
      };
    }),
    EventDates: event.Dates?.map(d => {
      try {
        const date = parse(d.Date, "yyyy-MM-dd HH:mm", new Date());
        return {
          eventDate: date,
          eventDateMonth: format(date, "MMM"),
          eventDateDay: format(date, "dd")
        };
      } catch (err) {
        return d;
      }
    }),

    firstDate: format(minDate, "EEE MMM dd @ hh:mma"),
    lastDate: format(maxDate, "EEE MMM dd @ hh:mma"),

    onSaleFormatted: event.OnSale
      ? format(
          parse(event.OnSale, "yyyy-MM-dd HH:mm", new Date()),
          "MM/dd/yyyy"
        )
      : null,

    notOnSale: isAfter(
      parse(event.OnSale, "yyyy-MM-dd HH:mm", new Date()),
      new Date()
    ),
    eventOver: isAfter(new Date(), maxDate),
    ...remainingCustomizations
  };
};
