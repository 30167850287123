<template>
  <v-container v-if="campaign && 1 == 2">
    <h3>Donate</h3>
    <v-img
      class="float-right"
      width="250"
      :src="campaign.photo.average"
    ></v-img>
    <div v-html="campaign.content"></div>
    <v-row>
      <v-col md="4">
        <v-select
          outlined
          color="black"
          :items="suggestedAmounts"
          item-text="text"
          item-value="value"
          hide-selected
          :value="donationAmount"
          @input="updateValue"
        ></v-select>
      </v-col>
      <v-col md="4" v-if="showOtherField">
        <text-input
          :value="donationAmountOther"
          @input="updateOtherAmount"
        ></text-input>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import TextInput from "./TextInput";

export default {
  components: { TextInput },
  props: ["campaign"],
  computed: {
    suggestedAmounts() {
      return [
        ...this.campaign.suggested_amounts,
        {
          text: "Other",
          amount: "Other"
        }
      ];
    },
    showOtherField() {
      return this.donationAmount === "Other";
    },
    ...mapState({
      donationAmount: state => state.donationAmount,
      donationAmountOther: state => state.donationAmountOther
    })
  },
  methods: {
    updateValue(value) {
      this.$store.commit("STORE_DONATION_AMOUNT", value);
    },
    updateOtherAmount(value) {
      this.$store.commit("STORE_DONATION_AMOUNT_OTHER", value);
    }
  }
};
</script>
