<template>
  <v-container class="white pa-4">
    <div class="text-center" v-if="!pkg">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="pkg">
      <package-header :pkg="pkg" :showReturnButton="true"></package-header>
      <v-row class="my-2 mx-8">
        <v-col cols="12" md="9">
          <h2>{{ pkg.Title }}</h2>
          <div class="text-caption" v-if="pkg.Producer">
            Presented by {{ pkg.Producer }}
          </div>
        </v-col>
        <v-col cols="12" md="3" class="primary--text">
          <span class="text-h5">${{ pkg.Price / 100 }}</span>
          <div v-if="pkg.EarlyPurchaseIntervalLastDay" class="price-subtitle">
            {{ pkg.FullPrice | toCurrency }} starting
            {{ pkg.EarlyPurchaseIntervalLastDay }}
          </div>
        </v-col>
        <v-divider></v-divider>
      </v-row>
      <v-row class="my-2 mx-8">
        <v-col cols="12" md="12">
          <div class="text-h5" v-if="pkg.firstDate === pkg.lastDate">
            {{ pkg.lastDate }}
          </div>
          <div class="text-h5" v-if="pkg.firstDate !== pkg.lastDate">
            {{ pkg.firstDate }} - {{ pkg.lastDate }}
          </div>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-2 mx-8">
        <v-col cols="12" md="12">
          <div class="text-h5">Included:</div>
          <v-list two-line>
            <v-list-item
              v-for="ticket of pkg.Tickets"
              :key="ticket.TicketTypeID"
            >
              <v-list-item-avatar>
                <v-icon class="primary lighten-2" dark>
                  mdi-ticket-confirmation-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-text="ticket.TitleLine"
                ></v-list-item-title>

                <v-list-item-subtitle
                  v-text="ticket.EventTitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="my-2 mx-8" v-if="false">
        <v-col cols="12" md="12">
          <p class="sold-out font-weight-bold red--text">Sold Out</p>
        </v-col>
      </v-row>

      <v-row class="my-2 mx-8" v-if="pkg.Price !== 0">
        <v-col cols="12" md="9">
          <div class="text-h5">Choose quantity:</div>
        </v-col>
        <v-col cols="12" md="3">
          <quantity-selector
            index="1"
            :quantity="quantity"
            @onQuantityChange="setQuantity"
            name="Combo Package"
            :price="pkg.Price"
          ></quantity-selector>
        </v-col>
      </v-row>
      <v-row justify="center" class="pb-2" v-if="pkg.Price !== 0">
        <v-btn
          depressed
          color="primary"
          @click="requestTickets"
          :disabled="quantity <= 0"
          >Add to Cart</v-btn
        >
      </v-row>
    </div>
    <!-- end of if pkg -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { findPackage } from "@/repositories/PackageRepository";
import QuantitySelector from "@/components/shared/QuantitySelector";
import PackageHeader from "./includes/PackageHeader.vue";

export default {
  components: {
    QuantitySelector,
    PackageHeader
  },
  created() {
    findPackage(this.$route.params.code).then(p => {
      this.$store.commit("STORE_PACKAGE", p);
      this.$store.commit("STORE_CUSTOMER", {
        BannerImage: p.BannerImage,
        CustomerCategoryName: p.CustomerCategoryName,
        CustomerListingUrl: p.CustomerListingUrl
      });
      this.$store.dispatch("LOAD_TICKET_QUANTITIES", { isPackage: true });
    });
  },
  computed: mapState({
    pkg: state => state.package,
    order: state => state.order,
    BannerImage() {
      return this.pkg?.BannerImage
        ? `https://www.tempotickets.com/tempotickets/file_store/${this.pkg.BannerImage}`
        : null;
    },
    quantity: state => state.ticketQuantities?.[0] || 0
  }),
  methods: {
    setQuantity(newQty) {
      this.$store.commit("UPDATE_TICKET_QUANTITIES", {
        index: 0,
        quantity: newQty
      });
    },
    requestTickets() {
      this.$router.push("/packageInventoryRequest");
    }
  }
};
</script>

<style scoped>
.price-subtitle {
  color: #919699;
  font-size: 13px;
}
</style>
