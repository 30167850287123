<template>
  <v-stepper alt-labels flat>
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step >= 1">Order</v-stepper-step>
      <v-divider :class="step > 1 ? 'primary' : ''"></v-divider>
      <v-stepper-step step="2" :complete="step >= 2">Payment</v-stepper-step>
      <v-divider :class="step > 2 ? 'primary' : ''"></v-divider>
      <v-stepper-step step="3" :complete="step >= 3">Complete</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: ["step"]
};
</script>
