import { min, max, format, isAfter, parse } from "date-fns";

export const formatSinglePackage = pkg => {
  let minDate = null;
  let maxDate = null;
  const dates = pkg.Dates?.map(d =>
    parse(d.Date, "yyyy-MM-dd HH:mm", new Date())
  );

  try {
    minDate = min(dates);
    maxDate = max(dates);
  } catch (err) {
    console.log("DATE ERROR", err);
  }

  const isOffSale =
    isAfter(new Date(), maxDate) ||
    (pkg.OffSale && isAfter(new Date(pkg.OffSale), new Date()));
  let isOnSale = isAfter(new Date(pkg.OnSale), new Date());
  if (isOnSale && pkg.OffSale) {
    isOnSale = !isAfter(new Date(pkg.OffSale), new Date());
  }
  if (isOnSale && maxDate) {
    isOnSale = !isAfter(new Date(), maxDate);
  }

  return {
    ...pkg,

    firstDate: format(minDate, "EEE MMM dd @ hh:mma"),
    lastDate: format(maxDate, "EEE MMM dd @ hh:mma"),

    onSaleFormatted: pkg.OnSale
      ? format(new Date(pkg.OnSale), "MM/dd/yyyy")
      : null,
    isOnSale,
    isOffSale,
    eventOver: isAfter(new Date(), maxDate),
    Tickets: pkg.Tickets.map(t => {
      return {
        ...t,
        TitleLine:
          `${t.EventTitle} / ` +
          format(
            parse(t.Date, "yyyy-MM-dd HH:mm", new Date()),
            "EEE MMM d @ h:mma"
          )
      };
    })
  };
};

export const formatPackagesInList = packages => {
  return packages.map(pkg => {
    let minDate = null;
    let maxDate = null;
    const dates = pkg.Dates?.map(d =>
      parse(d.Date, "yyyy-MM-dd HH:mm", new Date())
    );

    try {
      minDate = min(dates);
      maxDate = max(dates);
    } catch (err) {
      console.log("DATE ERROR", err);
    }

    const isOffSale =
      isAfter(new Date(), maxDate) ||
      (pkg.OffSale && isAfter(new Date(), new Date(pkg.OffSale)));
    let isOnSale = isAfter(new Date(pkg.OnSale), new Date());
    if (isOnSale && pkg.OffSale) {
      isOnSale = !isAfter(new Date(pkg.OffSale), new Date());
    }
    if (isOnSale && maxDate) {
      isOnSale = !isAfter(new Date(), maxDate);
    }

    return {
      ...pkg,
      isOnSale,
      isOffSale,
      minDate: format(minDate, "EEE MMM d @ h:mma"),
      maxDate: format(maxDate, "EEE MMM d @ h:mma"),
      Price: parseInt(pkg.Price),
      isEvent: false
    };
  });
};
