import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import AccountFoundScreen from "@/components/checkout/AccountFoundScreen";
import AccountNotFoundScreen from "@/components/checkout/AccountNotFoundScreen";
import DashboardScreen from "@/components/DashboardScreen";
import EventQuestionsScreen from "@/components/checkout/EventQuestionsScreen";
import EventScreen from "@/components/checkout/EventScreen";
import ExpiredCartScreen from "@/components/checkout/ExpiredCartScreen";
import HelloWorld from "@/components/HelloWorld";
import InventoryRequestScreen from "@/components/checkout/InventoryRequestScreen";
import PackageInventoryRequestScreen from "@/components/checkout/PackageInventoryRequestScreen";
import PaymentScreen from "@/components/checkout/PaymentScreen";
import PaymentLanding from "@/components/checkout/PaymentLanding";
import PackageScreen from "@/components/checkout/PackageScreen";
import LandingScreen from "@/components/listing/LandingScreen";
import ViewTicketScreen from "@/components/orders/ViewTicketScreen";
import Login from "@/components/login/Login";
import store from "@/state/store";

const routes = [
  {
    component: HelloWorld,
    name: "hello-world",
    path: "/"
  },
  {
    component: Login,
    name: "login",
    path: "/login"
  },
  // START CUSTOM REDIRECTS
  // NOTE: Force to Legacy URL
  {
    path: "/listing/y0nljh",
    beforeEnter() {
      window.location.replace("https://www.tempotickets.com/listing/y0nljh");
    }
  },
  // Pride Block Party 2024 - Weekend Pass
  {
    path: "/package/w6njfb",
    beforeEnter() {
      window.location.replace("https://www.tempotickets.com/package/w6njfb");
    }
  },
  // Pride Block Party 2024 - FRIDAY
  {
    path: "/event/i9tnVD",
    beforeEnter() {
      window.location.replace("https://www.tempotickets.com/event/i9tnVD");
    }
  },
  // Pride Block Party 2024 - SATURDAY
  {
    path: "/event/99tVen",
    beforeEnter() {
      window.location.replace("https://www.tempotickets.com/event/99tVen");
    }
  },
  // Pride Block Party 2024 - SUNDAY
  {
    path: "/event/G9tn3J",
    beforeEnter() {
      window.location.replace("https://www.tempotickets.com/event/G9tn3J");
    }
  },
  // END CUSTOM REDIRECTS
  // !! disabled
  // {
  //   component: CreateAccount,
  //   name: "createAccount",
  //   path: "/createAccount"
  // },
  {
    component: PaymentScreen,
    name: "payment",
    path: "/payment",
    meta: {
      requiresEvent: true
    }
  },
  {
    component: PaymentLanding,
    name: "paymentComplete",
    path: "/paymentComplete"
  },
  {
    component: DashboardScreen,
    name: "dashboard",
    path: "/dashboard"
  },
  {
    component: LandingScreen,
    name: "listing",
    path: "/listing/:code",
    props: true
  },
  {
    component: EventScreen,
    name: "event",
    path: "/event/:code",
    props: true
  },
  {
    component: PackageScreen,
    name: "package",
    path: "/package/:code",
    props: true
  },
  {
    component: EventQuestionsScreen,
    name: "eventQuestions",
    path: "/eventQuestions",
    props: true,
    meta: {
      requiresEvent: true
    }
  },
  {
    component: AccountFoundScreen,
    name: "accountFound",
    path: "/accountFound",
    props: true,
    meta: {
      requiresEvent: true
    }
  },
  {
    component: AccountNotFoundScreen,
    name: "accountNotFound",
    path: "/accountNotFound",
    props: true,
    meta: {
      requiresEvent: true
    }
  },
  {
    component: ExpiredCartScreen,
    name: "expired",
    path: "/expired",
    props: true
  },
  {
    component: InventoryRequestScreen,
    name: "inventoryRequest",
    path: "/inventoryRequest",
    props: true
  },
  {
    component: PackageInventoryRequestScreen,
    name: "packageInventoryRequest",
    path: "/packageInventoryRequest",
    props: true
  },
  {
    component: ViewTicketScreen,
    name: "ticket",
    path: "/tickets/:orderCode/:passKey/:barcode",
    props: true
  },
  {
    component: ViewTicketScreen,
    name: "tickets",
    path: "/tickets/:orderCode/:passKey",
    props: true
  },
  {
    component: EventScreen,
    name: "customEvent",
    path: "/:code",
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  window.scroll(0, 0);
  if (to.matched.some(record => record.meta.requiresEvent)) {
    if (store.getters.isEvent || store.getters.isPackage) {
      next();
      return;
    }
    next("/expired");
  } else {
    next();
  }
});

export default router;
