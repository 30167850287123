module.exports = {
  rules: {
    email: value =>
      (!!value &&
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) ||
      "Email " + value + " is invalid.",
    required: value => !!value || "Required.",
    passwordLength: value =>
      (!!value && value.length >= 10) ||
      "Password must be 10 characters or more."
  }
};
