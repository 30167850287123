<template>
  <div>
    <div
      v-if="selectedTicket && pageWidth <= pageBreak"
      v-touch="touchHandler"
      v-touch:swipe="swipeHandler"
    >
      <mobile-ticket
        :ticket="selectedTicket"
        @onNextTicket="nextTicket"
        @onPreviousTicket="previousTicket"
        :index="ticketIndex + 1"
        :total="tickets.length"
      ></mobile-ticket>
    </div>
    <div v-if="pageWidth > pageBreak">
      <div class="pr-2 pt-2 printing-note">
        Printing is not required, check-in is faster using a phone.
      </div>
      <printable-ticket
        v-for="(ticket, index) in tickets"
        :key="ticket.barcode"
        :ticket="ticket"
        :index="index + 1"
        :total="tickets.length"
      ></printable-ticket>
    </div>
    <v-footer class="text-caption">
      <v-row>
        <v-col>Powered by Tempo</v-col>
        <v-col class="text-right">Purchased by: {{ billingName }}</v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import { getWindowSize } from "@/services/WindowSize";
import MobileTicket from "@/components/orders/MobileTicket";
import PrintableTicket from "@/components/orders/PrintableTicket";
import { findPurchasedTickets } from "@/repositories/TicketRepository";

export default {
  components: {
    MobileTicket,
    PrintableTicket
  },
  data() {
    return {
      pageBreak: 600,
      selectedTicket: null,
      ticketIndex: 0,
      billingName: "",
      tickets: []
    };
  },
  mounted() {
    // Path will be like /ticket/PQPDnC/f85fcd3d9e70eb105e39b51dbb3a99c7a70a80ee
    // the first "/ticket" is what gets us to this screen,
    //  so, the remaining ends up here.
    const { orderCode, passKey, barcode } = this.$route.params;
    findPurchasedTickets(orderCode, passKey).then(response => {
      this.tickets = barcode
        ? response.order.tickets.filter(t => t.barcode === barcode)
        : response.order.tickets;
      this.billingName = response.order.billingName;
      this.selectedTicket = this.tickets[this.ticketIndex];
    });
  },
  computed: {
    pageWidth() {
      return getWindowSize().width;
    }
  },
  methods: {
    swipeHandler(direction) {
      if (direction === "left" && this.ticketIndex < this.tickets.length - 1) {
        this.selectedTicket = this.tickets[++this.ticketIndex];
      }
      if (direction === "right" && this.ticketIndex > 0) {
        this.selectedTicket = this.tickets[--this.ticketIndex];
      }
    },
    nextTicket() {
      return this.swipeHandler("left");
    },
    previousTicket() {
      return this.swipeHandler("right");
    }
  }
};
</script>
<style scoped>
@media print {
  .printing-note {
    display: none;
  }
}
</style>
